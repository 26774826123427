/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */

import { useTranslation } from 'react-i18next'

export type Options = {
    label: string
    value?: string | number
}

interface SelectProps {
    options: Options[]
    horizontal?: boolean
    showPlaceHolder?: boolean
    placeHolderOption?: string
    title?: string
    label?: string
    customStyle?: string
    selectWidth?: string
    OptionStyle?: string
    name: string
    noLabel?: boolean
    value?: string | number
    required?: boolean
    onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
    disabled?: boolean
    leftContent?: React.ReactElement
    rightContent?: React.ReactElement
    error?: string | boolean
}

const Select = (props: SelectProps) => {
    const {
        options,
        placeHolderOption,
        showPlaceHolder,
        horizontal,
        label,
        customStyle,
        noLabel,
        title,
        name,
        selectWidth,
        value,
        required,
        onChange,
        OptionStyle,
        rightContent,
        leftContent,
        disabled,
        error,
    } = props

    const { t } = useTranslation()

    const outlineInput = 'border-2 border-inputBorder placeholder-placeholderColor'
    const inputClass =
        'block border-1 py-2 rounded-lg focus:outline-none focus:shadow-outline flex items-center w-full bg-white'

    return (
        <div
            className={`flex ${
                horizontal
                    ? 'flex-col md:flex-row md:items-center justify-between'
                    : 'flex-col justify-start'
            }   gap-1 w-full`}
            title={title}
        >
            {!noLabel && (
                <div className={`${horizontal ? (selectWidth ? 'flex-1' : 'w-[27%] mt-2') : ''}`}>
                    {label && (
                        <label
                            htmlFor={name}
                            className={`${horizontal ? 'text-md' : 'text-md'} px-1 flex gap-2`}
                        >
                            {t(label)} {required && <span className="text-red-700">*</span>}
                        </label>
                    )}
                </div>
            )}
            <div className={`${horizontal ? selectWidth || 'w-[70%]' : ''}`}>
                <div className="flex items-center gap-1">
                    {leftContent && <span className="pr-2">{leftContent}</span>}
                    <select
                        name={name}
                        className={`${customStyle} ${inputClass} ${outlineInput} ${
                            error && 'border-red-700'
                        }`}
                        onChange={onChange}
                        value={value}
                        disabled={disabled}
                    >
                        {showPlaceHolder && (
                            <option className={OptionStyle} value="">
                                {placeHolderOption ? t(placeHolderOption) : 'Select Options'}
                            </option>
                        )}
                        {options.map((option: Options, index: number) => (
                            <option
                                className={OptionStyle}
                                key={`${option.label}-${index}`}
                                value={option.value}
                            >
                                {t(option.label)}
                            </option>
                        ))}
                    </select>
                    {rightContent && <span className="pr-2">{rightContent}</span>}
                </div>
                {error && <span className="text-red-700 text-xs">{error}</span>}
            </div>
        </div>
    )
}

export default Select
