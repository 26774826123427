/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react-hooks/rules-of-hooks */
import Table from 'components/table'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select, { Options } from 'components/select'
import Button from 'components/button'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ROLE_TYPE, useAuth } from 'modules/auth/context/useAuth'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { formatCurrency, getCurrencySymbol } from 'utils/currency/formatCurrency'
import Input from 'components/input'
import { CALCULATION_STATUS } from 'utils/calculationStatus'
import Modal from 'react-responsive-modal'
import { sortBy } from 'lodash'
import TextArea from 'components/textArea'
import { PRICING_STATUS } from 'utils/pricingStatus'
import EditableInputCell from '../components/EditableInputCell'
import EditableSelectCell from '../components/EditableSelectCell'
import {
    approveAll,
    checkApproval,
    editQuoteInPricingApproval,
    getApprovalOverview,
    getQuote,
    getRequestApprovalEmails,
    IEditQuoteInPricingApproval,
    sendRequestApprovalEmails,
} from '../queries/quotes'
import { IAdjustType, PricingQueries } from '../queries/pricing'
import { useQuoteLayout } from '../QuoteLayout'

const PricingApproval = (props: {
    investmentGroupOptions: Options[]
    categoryOptions: Options[]
}) => {
    const { refetch: refetchQuoteData, quoteConfigurations } = useQuoteLayout()
    const { investmentGroupOptions, categoryOptions } = props
    const { t } = useTranslation()
    const { orderId, quoteId } = useParams()
    const { authorizationMatrix } = useAuth()
    const [selectedIndex, setSelectedIndex] = useState<number>(0)
    const { userExtra } = useAuth()
    const [dataWithPrice, setDataWithPrice] = useState<any>([])
    const [email, setEmail] = useState<string>('')
    const [showSendEmailModal, setShowSendEmailModal] = useState<boolean>(false)
    const [approvalEmailOptions, setApprovalEmailOptions] = useState<any[]>([])
    const [remark, setRemark] = useState<string>('')

    const deliveryCostOptions: Options[] = [
        {
            label: `${getCurrencySymbol()} 285 per truck (${getCurrencySymbol()} 185 per pallettruck)`,
            value: `${getCurrencySymbol()} 285 per truck (${getCurrencySymbol()} 185 per pallettruck)`,
        },
        {
            label: t('fields.no_delivery_cost'),
            value: 'n.v.t',
        },
        {
            label: 'other',
            value: 'other_value',
        },
    ]

    const contractCostOptions: Options[] = [
        {
            label: `${getCurrencySymbol()} 3,25 per maand per truck`,
            value: `${getCurrencySymbol()} 3,25 per maand per truck`,
        },
        {
            label: `${getCurrencySymbol()} 3,25 per maand per contract/ lease overeenkomst`,
            value: `${getCurrencySymbol()} 3,25 per maand per contract/ lease overeenkomst`,
        },
        {
            label: t('fields.no_contract_cost'),
            value: 'n.v.t',
        },
        {
            label: 'other',
            value: 'other_value',
        },
    ]

    const debtCollectionOptions: Options[] = [
        {
            label: t('fields.yes'),
            value: 'yes',
        },
        {
            label: `${getCurrencySymbol()} 10 per maand per factuur`,
            value: `${getCurrencySymbol()} 10 per maand per factuur`,
        },
        {
            label: t('fields.no_collection_fee'),
            value: 'n.v.t',
        },
        {
            label: 'other',
            value: 'other_value',
        },
    ]

    const { isLoading: emailLoading } = useQuery(
        'getApprovalEmails',
        () => getRequestApprovalEmails(),
        {
            onSuccess: (data: any) => {
                const emails = data.data.data
                const fleetEmails = emails.fleet.map((fleet: any) => ({
                    name: `${fleet.name}`,
                    position: t('api.sales'),
                    email: fleet.email,
                }))
                const salesEmails = emails.sales.map((sales: any) => ({
                    name: `${sales.name}`,
                    position: t('api.sales'),
                    email: sales.email,
                }))
                const serviceEmails = emails.service.map((service: any) => ({
                    name: `${service.name}`,
                    position: t('api.fleet'),
                    email: service.email,
                }))
                const mdEmails = emails.md.map((service: any) => ({
                    name: `${service.name}`,
                    position: t('api.fleet'),
                    email: service.email,
                }))
                const srEmails = emails.sr.map((service: any) => ({
                    name: `${service.name}`,
                    position: t('api.fleet'),
                    email: service.email,
                }))
                setApprovalEmailOptions([
                    ...fleetEmails,
                    ...salesEmails,
                    ...serviceEmails,
                    ...mdEmails,
                    ...srEmails,
                ])
            },
            onError: () => {
                toast.error('message.approval_email_fetch_error')
            },
        },
    )

    const sendApprovalRequestEmail = useMutation(
        ['sendApprovalRequestEmail'],
        (params: { email: string; name: string; remark: string }) =>
            sendRequestApprovalEmails({
                quote_id: parseInt(String(quoteId), 10),
                email: params.email,
                name: params.name,
                remark,
                force_reset_flag: true,
            }),
        {
            onSuccess: () => {
                refetchQuoteData()
                toast.success(t('message.request_sent_success'))
                setShowSendEmailModal(false)
                setEmail('')
            },
            onError: () => {
                toast.error(t('message.request_sent_failed'))
            },
        },
    )

    const editQuoteInPricingApprovalMutation = useMutation(
        ['editQuoteInPricingApproval'],
        (params: IEditQuoteInPricingApproval) =>
            editQuoteInPricingApproval(parseFloat(String(quoteId)), params),
        {
            onSuccess: () => {
                toast.success(t('message.edit_quote_success'))
            },
            onError: () => {
                toast.error(t('message.error'))
            },
        },
    )

    const formik: any = useFormik<any>({
        initialValues: {
            deliveryCost: `${getCurrencySymbol()} 285 per truck (${getCurrencySymbol()} 185 per pallettruck)`,
            deliveryCostOther: '',
            contractCost: `${getCurrencySymbol()} 3,25 per maand per truck`,
            contractCostOther: '',
            noAutomaticDebtCollectionFee: 'yes',
            noAutomaticDebtCollectionFeeOther: '',
        },
        validationSchema: Yup.object().shape({
            deliveryCost: Yup.string().required(t('message.delivery_cost_required')),
            contractCost: Yup.string().required(t('message.contract_cost_required')),
            noAutomaticDebtCollectionFee: Yup.string().required(
                t('message.debt_collection_required'),
            ),
        }),
        onSubmit: values => {
            editQuoteInPricingApprovalMutation.mutate({
                delivery_cost:
                    values.deliveryCost !== 'other_value'
                        ? values.deliveryCost
                        : values.deliveryCostOther,
                contract_cost:
                    values.contractCost !== 'other_value'
                        ? values.contractCost
                        : values.contractCostOther,
                debt_collection_fees:
                    values.noAutomaticDebtCollectionFee !== 'other_value'
                        ? values.noAutomaticDebtCollectionFee
                        : values.noAutomaticDebtCollectionFeeOther,
            })
        },
    })

    useQuery(['getQuote'], () => getQuote(parseFloat(String(quoteId))), {
        onSuccess: data => {
            const quoteData = data.data.data
            if (quoteData.delivery_cost) {
                const isDeliveryCostOther = deliveryCostOptions.find(
                    cost => cost.value === quoteData.delivery_cost,
                )
                if (isDeliveryCostOther) {
                    formik.setFieldValue('deliveryCost', quoteData.delivery_cost)
                } else {
                    formik.setFieldValue('deliveryCost', 'other_value')
                    formik.setFieldValue('deliveryCostOther', quoteData.delivery_cost)
                }
            }
            if (quoteData.contract_cost) {
                const isContractCostOther = contractCostOptions.find(
                    cost => cost.value === quoteData.contract_cost,
                )
                if (isContractCostOther) {
                    formik.setFieldValue('contractCost', quoteData.contract_cost)
                } else {
                    formik.setFieldValue('contractCost', 'other_value')
                    formik.setFieldValue('contractCostOther', quoteData.contract_cost)
                }
            }
            if (quoteData.debt_collection_fees) {
                const isdebtCollectorOther = debtCollectionOptions.find(
                    cost => cost.value === quoteData.debt_collection_fees,
                )
                if (isdebtCollectorOther) {
                    formik.setFieldValue(
                        'noAutomaticDebtCollectionFee',
                        quoteData.debt_collection_fees,
                    )
                } else if (quoteData.debt_collection_fees) {
                    formik.setFieldValue('noAutomaticDebtCollectionFee', 'other_value')
                    formik.setFieldValue(
                        'noAutomaticDebtCollectionFeeOther',
                        quoteData.debt_collection_fees,
                    )
                } else {
                    formik.setFieldValue('noAutomaticDebtCollectionFee', 'n.v.t')
                }
            }
        },
        onError: () => toast.error(t('message.fetch_quote_error')),
    })

    const {
        isLoading: pricingApprovalLoading,
        data: pricingApprovalData,
        refetch,
    } = useQuery(
        ['getPricingApprovalList'],
        () => getApprovalOverview(parseFloat(String(quoteId))),
        {
            onSuccess: data => {
                const tempDataWithPrice: any = []
                data.data.data.data_with_pricing.forEach((d: any) => {
                    const index = tempDataWithPrice.findIndex(
                        (td: any) => td.novo_configuration_id === d.novo_configuration_id,
                    )
                    if (index >= 0) {
                        const tempObj = tempDataWithPrice[index]
                        const prevValue =
                            typeof tempObj.total_lease_price === 'object'
                                ? [...tempObj.total_lease_price]
                                : [tempObj.total_lease_price]
                        tempObj.total_lease_price = [...prevValue, d.total_lease_price]
                        tempDataWithPrice[index] = tempObj
                    } else {
                        tempDataWithPrice.push(d)
                    }
                })
                setDataWithPrice(sortBy(tempDataWithPrice, 'id'))
            },
            onError: () => {
                toast.error(t('message.fetch_pending_approval_failed'))
            },
        },
    )

    const ApproveAllMutation = useMutation(
        ['approve-all'],
        () => approveAll(parseFloat(String(quoteId))),
        {
            onSuccess: () => {
                refetch()
                refetchQuoteData()
                toast.success(t('message.quote_approve_success'))
            },
            onError: (error: any) => {
                toast.error(error?.message || t('message.quote_approve_failed'))
            },
        },
    )

    const adjustProfit = useMutation(
        ['adjustProfit'],
        (params: { configurationId: string; configurationPriceId: number; data: IAdjustType }) => {
            return PricingQueries.adjustProfit(
                parseFloat(String(params.configurationId)),
                params.configurationPriceId,
                params.data,
            )
        },
        {
            onSuccess: () => {
                refetchQuoteData()
                refetch()
                toast.success(t(`message.adjust_profit_success`))
            },
            onError: (error: any) => {
                toast.error(error?.message || t(`message.adjust_price_failed`))
            },
        },
    )

    const adjustInvestment = useMutation(
        ['adjustInvestment'],
        (params: { configurationId: string; configurationPriceId: number; data: IAdjustType }) => {
            return PricingQueries.adjustInvestment(
                parseFloat(String(params.configurationId)),
                params.configurationPriceId,
                params.data,
            )
        },
        {
            onSuccess: () => {
                refetchQuoteData()
                refetch()
                toast.success(t(`message.adjust_investment_success`))
            },
            onError: (error: any) => {
                toast.error(error?.message || t(`message.adjust_investment_failed`))
            },
        },
    )

    const adjustFactor = useMutation(
        ['adjustFactor'],
        (params: { configurationId: string; configurationPriceId: number; data: IAdjustType }) => {
            return PricingQueries.adjustFactor(
                parseFloat(String(params.configurationId)),
                params.configurationPriceId,
                params.data,
            )
        },
        {
            onSuccess: () => {
                refetchQuoteData()
                refetch()
                toast.success(t(`message.adjust_factor_success`))
            },
            onError: (error: any) => {
                toast.error(error?.message || t(`message.adjust_factor_failed`))
            },
        },
    )

    const adjustDiscount = useMutation(
        ['adjustDiscount'],
        (params: { configurationId: string; configurationPriceId: number; data: IAdjustType }) => {
            return PricingQueries.adjustDiscount(
                parseFloat(String(params.configurationId)),
                params.configurationPriceId,
                params.data,
            )
        },
        {
            onSuccess: () => {
                refetchQuoteData()
                refetch()
                toast.success(t('message.adjust_discount_success'))
            },
            onError: (error: any) => {
                toast.error(error?.message || t(`message.adjust_discount_failed`))
            },
        },
    )

    const adjustResidualValue = useMutation(
        ['adjustResidualValue'],
        (params: { configurationId: string; configurationPriceId: number; data: IAdjustType }) => {
            return PricingQueries.adjustResidualValue(
                parseFloat(String(params.configurationId)),
                params.configurationPriceId,
                params.data,
            )
        },
        {
            onSuccess: () => {
                refetchQuoteData()
                refetch()
                toast.success(t('message.adjust_residual_value_success'))
            },
            onError: (error: any) => {
                toast.error(error.message || t(`message.adjust_residual_value_failed`))
            },
        },
    )

    const adjustSpr = useMutation(
        ['adjustSpr'],
        (params: { configurationId: string; configurationPriceId: number; data: IAdjustType }) => {
            return PricingQueries.adjustSpr(
                parseFloat(String(params.configurationId)),
                params.configurationPriceId,
                params.data,
            )
        },
        {
            onSuccess: () => {
                refetchQuoteData()
                refetch()
                toast.success(t('message.adjust_spr_success'))
            },
            onError: (error: any) => {
                toast.error(error?.message || t(`message.adjust_spr_failed`))
            },
        },
    )

    const handleOnChange = (
        configurationId: string,
        configurationPriceId: number,
        name: string,
        value: number,
        status: CALCULATION_STATUS = CALCULATION_STATUS.DEFAULT,
    ) => {
        switch (name) {
            case 'profit_percentage':
                adjustProfit.mutate({
                    configurationId,
                    configurationPriceId,
                    data: {
                        value,
                        status: CALCULATION_STATUS.ADJUSTED,
                    },
                })
                break
            case 'additional_residual_percentage':
                adjustResidualValue.mutate({
                    configurationId,
                    configurationPriceId,
                    data: {
                        value,
                        status: CALCULATION_STATUS.ADJUSTED,
                    },
                })
                break
            case 'novo_quote_price':
                // toBeUpdatedValue.novo_quote_price = value
                break
            case 'manual_price':
                // toBeUpdatedValue.manual_price = value
                break
            case 'min_investment_group':
                adjustInvestment.mutate({
                    configurationId,
                    configurationPriceId,
                    data: {
                        value,
                        status: CALCULATION_STATUS.ADJUSTED,
                    },
                })
                break
            case 'discount_service_all_in':
                adjustDiscount.mutate({
                    configurationId,
                    configurationPriceId,
                    data: {
                        value,
                        status: CALCULATION_STATUS.ADJUSTED,
                        type: 'all_in',
                    },
                })
                break
            case 'discount_service_pm':
                adjustDiscount.mutate({
                    configurationId,
                    configurationPriceId,
                    data: {
                        value,
                        status: CALCULATION_STATUS.ADJUSTED,
                        type: 'preventive_maintenance',
                    },
                })
                break
            case 'duration_of_contract':
                // toBeUpdatedValue.duration_of_contract = value
                break
            case 'vpg_percentage':
                // toBeUpdatedValue.vpg_percentage = value
                break
            case 'special_price_request_percentage':
                // toBeUpdatedValue.special_price_request_percentage = value
                adjustSpr.mutate({
                    configurationId,
                    configurationPriceId,
                    data: {
                        value,
                        status: CALCULATION_STATUS.ADJUSTED,
                    },
                })
                break
            case 'standard_discount_percentage':
                // toBeUpdatedValue.standard_discount_percentage = value
                break
            case 'category':
                // toBeUpdatedValue.category = value
                break
            default:
                break
        }
    }

    const emailColumns = [
        {
            name: t('api.name'),
            selector: (row: any) => row.name,
        },
        {
            name: t('api.email'),
            selector: (row: any) => row.email,
        },
        {
            name: t('api.action'),
            cell: (row: any) => (
                <div className="w-fit">
                    <Button
                        label={t('api.send_request')}
                        isLoading={
                            (email === row.email && sendApprovalRequestEmail.isLoading) ||
                            Boolean(orderId)
                        }
                        onClick={() => {
                            setEmail(row.email)
                            sendApprovalRequestEmail.mutate({
                                email: row.email,
                                name: row.name,
                                remark,
                            })
                        }}
                        variant="outline"
                    />
                </div>
            ),
        },
    ]

    const columns = [
        {
            name: t('api.profit'),
            center: true,
            width: '10rem',
            cell: (
                row: {
                    novo_configuration_id: string
                    profit_percentage: string
                    configuration_price_id: number
                    price_status: number
                    noRequest: boolean
                },
                index: number,
            ) => (
                <EditableInputCell
                    name="profit_percentage"
                    originalValue={row.profit_percentage ? parseFloat(row.profit_percentage) : 0}
                    min={0}
                    max={100}
                    type="percentage"
                    selectedIndex={selectedIndex}
                    index={index}
                    disabled={row.noRequest || Boolean(orderId)}
                    success={adjustProfit.isSuccess}
                    error={adjustProfit.isError}
                    status={row.price_status}
                    loading={adjustProfit.isLoading || pricingApprovalLoading}
                    onApprove={() => {
                        adjustProfit.mutate({
                            configurationId: row.novo_configuration_id,
                            configurationPriceId: row.configuration_price_id,
                            data: {
                                value: parseFloat(row.profit_percentage),
                                status: CALCULATION_STATUS.APPROVED,
                            },
                        })
                    }}
                    onChange={value => {
                        setSelectedIndex(index)
                        handleOnChange(
                            row.novo_configuration_id,
                            row.configuration_price_id,
                            'profit_percentage',
                            value,
                            row.price_status,
                        )
                    }}
                />
            ),
        },
        {
            name: t('api.extra_residual_value'),
            center: true,
            width: '10rem',
            cell: (
                row: {
                    novo_configuration_id: string
                    additional_residual_percentage: string
                    configuration_price_id: number
                    additional_residual_status: number
                    noRequest: boolean
                },
                index: number,
            ) => (
                <EditableInputCell
                    name="extraResidualValue"
                    originalValue={parseFloat(row.additional_residual_percentage)}
                    min={0}
                    max={100}
                    type="percentage"
                    index={index}
                    selectedIndex={selectedIndex}
                    success={adjustResidualValue.isSuccess}
                    loading={adjustResidualValue.isLoading || pricingApprovalLoading}
                    error={adjustResidualValue.isError}
                    disabled={row.noRequest || Boolean(orderId)}
                    status={row.additional_residual_status}
                    onApprove={() =>
                        adjustResidualValue.mutate({
                            configurationId: row.novo_configuration_id,
                            configurationPriceId: row.configuration_price_id,
                            data: {
                                value: parseFloat(row.additional_residual_percentage),
                                status: CALCULATION_STATUS.APPROVED,
                            },
                        })
                    }
                    onChange={value => {
                        setSelectedIndex(index)
                        handleOnChange(
                            row.novo_configuration_id,
                            row.configuration_price_id,
                            'additional_residual_percentage',
                            value,
                            row.additional_residual_status,
                        )
                    }}
                />
            ),
        },
        {
            name: t('api.investment_group'),
            center: true,
            width: '10rem',
            cell: (
                row: {
                    novo_configuration_id: string
                    min_investment_group: number
                    configuration_price_id: number
                    investment_status: number
                    noRequest: boolean
                },
                index: number,
            ) => (
                <EditableSelectCell
                    name="min_investment_group"
                    options={investmentGroupOptions}
                    originalValue={row.min_investment_group}
                    success={adjustInvestment.isSuccess}
                    loading={adjustInvestment.isLoading || pricingApprovalLoading}
                    error={adjustInvestment.isError}
                    status={row.investment_status}
                    selectedIndex={selectedIndex}
                    disabled={row.noRequest || Boolean(orderId)}
                    index={index}
                    approve={() => {
                        adjustInvestment.mutate({
                            configurationId: row.novo_configuration_id,
                            configurationPriceId: row.configuration_price_id,
                            data: {
                                value: parseFloat(String(row.min_investment_group)),
                                status: CALCULATION_STATUS.APPROVED,
                            },
                        })
                    }}
                    onChange={value => {
                        setSelectedIndex(index)
                        handleOnChange(
                            row.novo_configuration_id,
                            row.configuration_price_id,
                            'min_investment_group',
                            parseFloat(String(value)),
                            row.investment_status,
                        )
                    }}
                />
            ),
        },
        {
            name: t('api.discount_service_all_in'),
            center: true,
            width: '10rem',
            cell: (
                row: {
                    additional_maintenance: string
                    maintenance: string
                    novo_configuration_id: string
                    discount_service_all_in: string
                    configuration_price_id: number
                    discount_service_status: number
                    noRequest: boolean
                    category: string | number
                    additional_theoretical_category: string | number
                    profit_percentage: string | number
                },
                index: number,
            ) =>
                row.maintenance === 'ALL_IN' ||
                row.additional_maintenance === 'ALL_IN' ||
                row.maintenance === 'ALL_IN_AND_PREVENTIVE_MAINTENANCE' ||
                row.additional_maintenance === 'ALL_IN_AND_PREVENTIVE_MAINTENANCE' ? (
                    <EditableInputCell
                        name="discount_service_all_in"
                        originalValue={parseFloat(row.discount_service_all_in)}
                        min={-100}
                        max={100}
                        type="percentage"
                        disabled={row.noRequest || Boolean(orderId)}
                        selectedIndex={selectedIndex}
                        status={row.discount_service_status}
                        success={adjustDiscount.isSuccess}
                        loading={adjustDiscount.isLoading || pricingApprovalLoading}
                        error={adjustDiscount.isError}
                        index={index}
                        category={Number(row.additional_theoretical_category || row.category)}
                        profitPercentage={Number(row.profit_percentage)}
                        onApprove={() =>
                            adjustDiscount.mutate({
                                configurationId: row.novo_configuration_id,
                                configurationPriceId: row.configuration_price_id,
                                data: {
                                    value: parseFloat(row.discount_service_all_in),
                                    status: CALCULATION_STATUS.APPROVED,
                                    type: 'all_in',
                                },
                            })
                        }
                        onChange={value => {
                            setSelectedIndex(index)
                            handleOnChange(
                                row.novo_configuration_id,
                                row.configuration_price_id,
                                'discount_service_all_in',
                                value,
                                row.discount_service_status,
                            )
                        }}
                    />
                ) : null,
        },
        {
            name: t('api.discount_service_pm'),
            center: true,
            width: '10rem',
            cell: (
                row: {
                    maintenance: string
                    additional_maintenance: string
                    novo_configuration_id: string
                    discount_service_pm: string
                    configuration_price_id: number
                    discount_service_status: number
                    noRequest: boolean
                },
                index: number,
            ) =>
                row.maintenance === 'PREVENTIVE_MAINTAINENCE' ||
                row.additional_maintenance === 'PREVENTIVE_MAINTAINENCE' ||
                row.maintenance === 'ALL_IN_AND_PREVENTIVE_MAINTENANCE' ||
                row.additional_maintenance === 'ALL_IN_AND_PREVENTIVE_MAINTENANCE' ? (
                    <EditableInputCell
                        name="discount_service_pm"
                        originalValue={parseFloat(row.discount_service_pm)}
                        min={-100}
                        max={100}
                        type="percentage"
                        selectedIndex={selectedIndex}
                        disabled={row.noRequest || Boolean(orderId)}
                        status={row.discount_service_status}
                        success={adjustDiscount.isSuccess}
                        loading={adjustDiscount.isLoading || pricingApprovalLoading}
                        error={adjustDiscount.isError}
                        index={index}
                        onApprove={() =>
                            adjustDiscount.mutate({
                                configurationId: row.novo_configuration_id,
                                configurationPriceId: row.configuration_price_id,
                                data: {
                                    value: parseFloat(row.discount_service_pm),
                                    status: CALCULATION_STATUS.APPROVED,
                                    type: 'preventive_maintenance',
                                },
                            })
                        }
                        onChange={value => {
                            setSelectedIndex(index)
                            handleOnChange(
                                row.novo_configuration_id,
                                row.configuration_price_id,
                                'discount_service_pm',
                                value,
                                row.discount_service_status,
                            )
                        }}
                    />
                ) : null,
        },
        {
            name: t('api.vpg'),
            center: true,
            width: '10rem',
            cell: (
                row: {
                    novo_configuration_id: string
                    vpg_percentage: string
                    configuration_price_id: number
                },
                index: number,
            ) => (
                <EditableInputCell
                    name="vpg_percentage"
                    originalValue={parseFloat(row.vpg_percentage)}
                    min={0}
                    max={100}
                    disabled
                    selectedIndex={selectedIndex}
                    index={index}
                    type="percentage"
                    onChange={value => {
                        setSelectedIndex(index)
                        handleOnChange(
                            row.novo_configuration_id,
                            row.configuration_price_id,
                            'vpg_percentage',
                            value,
                        )
                    }}
                />
            ),
        },
        {
            name: t('api.spr'),
            center: true,
            width: '10rem',
            cell: (
                row: {
                    novo_configuration_id: string
                    special_price_request_percentage: string
                    configuration_price_id: number
                    spr_status: number
                },
                index: number,
            ) => (
                <EditableInputCell
                    name="special_price_request_percentage"
                    originalValue={parseFloat(row.special_price_request_percentage)}
                    min={0}
                    max={100}
                    status={row.spr_status}
                    selectedIndex={selectedIndex}
                    disabled={Boolean(orderId)}
                    success={adjustSpr.isSuccess}
                    loading={adjustSpr.isLoading || pricingApprovalLoading}
                    error={adjustSpr.isError}
                    index={index}
                    type="percentage"
                    onApprove={() => {
                        adjustSpr.mutate({
                            configurationId: row.novo_configuration_id,
                            configurationPriceId: row.configuration_price_id,
                            data: {
                                value: parseFloat(row.special_price_request_percentage),
                                status: CALCULATION_STATUS.APPROVED,
                            },
                        })
                    }}
                    onChange={value => {
                        setSelectedIndex(index)
                        handleOnChange(
                            row.novo_configuration_id,
                            row.configuration_price_id,
                            'special_price_request_percentage',
                            value,
                        )
                    }}
                />
            ),
        },
        {
            name: t('api.standard_discount'),
            center: true,
            width: '10rem',
            cell: (
                row: {
                    novo_configuration_id: string
                    standard_discount_percentage: string
                    configuration_price_id: number
                },
                index: number,
            ) => (
                <EditableInputCell
                    name="standard_discount_percentage"
                    originalValue={parseFloat(row.standard_discount_percentage)}
                    min={0}
                    max={100}
                    disabled
                    selectedIndex={selectedIndex}
                    index={index}
                    type="percentage"
                    onChange={value => {
                        setSelectedIndex(index)
                        handleOnChange(
                            row.novo_configuration_id,
                            row.configuration_price_id,
                            'standard_discount_percentage',
                            value,
                        )
                    }}
                />
            ),
        },
        {
            name: t('api.category'),
            center: true,
            width: '10rem',
            cell: (
                row: {
                    novo_configuration_id: string
                    category: number
                    additional_theoretical_category: number
                    configuration_price_id: number
                },
                index: number,
            ) => (
                <EditableSelectCell
                    name="category"
                    options={categoryOptions}
                    originalValue={row.category || row.additional_theoretical_category}
                    disabled
                    selectedIndex={selectedIndex}
                    index={index}
                    onChange={value => {
                        setSelectedIndex(index)
                        handleOnChange(
                            row.novo_configuration_id,
                            row.configuration_price_id,
                            'category',
                            parseFloat(String(value)),
                        )
                    }}
                />
            ),
        },
        {
            name: t('api.date_requested'),
            center: true,
            width: '10rem',
            cell: (row: { date_requested: string; duration_of_contracts: string }) => {
                return (
                    <div className="flex flex-col gap-2">
                        {row.duration_of_contracts
                            ?.split(',')
                            .sort((a: any, b: any) => a - b)
                            .map((duration: any, index: number) => (
                                <p
                                    className={`text-black text-right w-full text-bold ${
                                        index ===
                                        Math.floor(
                                            row.duration_of_contracts
                                                ?.split(',')
                                                .sort((a: any, b: any) => a - b).length / 2,
                                        )
                                            ? ''
                                            : 'invisible'
                                    }`}
                                >
                                    {row.date_requested}
                                </p>
                            ))}
                    </div>
                )
            },
        },
    ]

    const fixedIdColumns = [
        {
            name: t('api.configuration_id'),
            left: true,
            width: '80px',
            cell: (row: { novo_configuration_id: number }) => {
                return <p className="text-black w-full text-bold">{row.novo_configuration_id}</p>
            },
        },
        {
            name: t('api.model'),
            center: true,
            cell: (row: { model: string }) => {
                return <p className="text-black text-right w-full text-bold">{row.model}</p>
            },
        },
        {
            name: t('api.duration_of_contract'),
            center: true,
            width: '10rem',
            cell: (row: {
                novo_configuration_id: string
                duration_of_contracts: string
                configuration_price_id: number
            }) => {
                return (
                    <div className="flex flex-col gap-2">
                        {row.duration_of_contracts
                            ?.split(',')
                            .sort((a: any, b: any) => a - b)
                            .map((duration: any) => (
                                <p className="text-black text-right w-full text-bold">{duration}</p>
                            ))}
                    </div>
                )
            },
        },
    ]

    const fixedTableColumns = [
        {
            name: t('api.quote_price'),
            center: true,
            width: '10rem',
            cell: (row: {
                novo_configuration_id: string
                novo_quote_price: string
                configuration_price_id: number
                manual_price: string
            }) => (
                <p>
                    {formatCurrency(
                        Number(
                            parseFloat(
                                row.manual_price ? row.manual_price : row.novo_quote_price,
                            ).toFixed(0),
                        ),
                    )}
                </p>
            ),
        },
        {
            name: t('api.sales_price'),
            center: true,
            width: '10rem',
            cell: (row: {
                novo_configuration_id: string
                manual_price: string
                novo_quote_price: string
                configuration_price_id: number
                is_manual_price_valid: boolean
            }) => (
                <p>
                    {row.manual_price && row.is_manual_price_valid
                        ? formatCurrency(Number(parseFloat(row.manual_price).toFixed(0)))
                        : formatCurrency(Number(parseFloat(row.novo_quote_price).toFixed(0)))}
                </p>
            ),
        },
        {
            name: t('api.profit_price'),
            right: true,
            width: '10rem',
            cell: (row: { profit_amount: string }) => {
                return (
                    <p className="text-black text-right w-full text-bold">
                        {formatCurrency(Number(parseFloat(row.profit_amount).toFixed(0)))}
                    </p>
                )
            },
        },
        {
            name: t('api.lease_price_total'),
            right: true,
            width: '10rem',
            cell: (row: {
                total_lease_price: string[]
                duration_of_contracts: string
                hideChecked: boolean
            }) => {
                const durationOfContract = row.duration_of_contracts.split(',')
                const totalLeasePrice: any = row.total_lease_price
                const mergedArray: any = []
                durationOfContract.forEach((duration, index: number) => {
                    mergedArray.push({
                        duration,
                        leasePrice: totalLeasePrice[index],
                    })
                })
                return (
                    <div className="flex flex-col gap-2">
                        {typeof row.total_lease_price === 'string' ? (
                            <p className="text-black text-right w-full text-bold">
                                {formatCurrency(
                                    Number(parseFloat(row.total_lease_price).toFixed(0)),
                                )}
                            </p>
                        ) : (
                            sortBy(mergedArray, 'duration')?.map((lp: any) => (
                                <p className="text-black text-right w-full text-bold">
                                    {formatCurrency(Number(parseFloat(lp.leasePrice).toFixed(0)))} (
                                    {lp.duration})
                                </p>
                            ))
                        )}
                    </div>
                )
            },
        },
    ]

    return (
        <div className="h-full w-full flex items-end flex-col gap-4">
            <div className="flex gap-4">
                {!quoteConfigurations?.every(
                    (obj: any) => obj?.configuration_price_status === PRICING_STATUS.APPROVED,
                ) &&
                    (userExtra.role_id === ROLE_TYPE.account_manager ||
                        userExtra.role_id === ROLE_TYPE.key_account_manager ||
                        userExtra.role_id === ROLE_TYPE.sales_manager ||
                        userExtra.role_id === ROLE_TYPE.sales_manager ||
                        userExtra.role_id === ROLE_TYPE.field_sales_manager ||
                        userExtra.role_id === ROLE_TYPE.managing_director) &&
                    !orderId && (
                        <div className="w-fit">
                            <Button
                                label={t('api.send_request_email')}
                                onClick={() => {
                                    setShowSendEmailModal(true)
                                }}
                                variant="primary"
                            />
                        </div>
                    )}
                <div className="w-full flex justify-end gap-4">
                    {(userExtra.role_id === ROLE_TYPE.sales_manager ||
                        userExtra.role_id === ROLE_TYPE.managing_director ||
                        userExtra.role_id === ROLE_TYPE.sales_support) &&
                        !orderId && (
                            <div className="w-fit">
                                <Button
                                    label={
                                        quoteConfigurations?.every(
                                            (obj: any) =>
                                                obj?.configuration_price_status ===
                                                PRICING_STATUS.APPROVED,
                                        )
                                            ? t('api.approved')
                                            : t('api.approve_all')
                                    }
                                    isLoading={ApproveAllMutation.isLoading}
                                    disabled={
                                        ApproveAllMutation.isLoading ||
                                        quoteConfigurations?.every(
                                            (obj: any) =>
                                                obj?.configuration_price_status ===
                                                PRICING_STATUS.APPROVED,
                                        )
                                    }
                                    onClick={() => ApproveAllMutation.mutate()}
                                    variant="outline"
                                />
                            </div>
                        )}
                </div>
            </div>
            <div className="flex w-full">
                <div className="w-fit">
                    <Table
                        columns={fixedIdColumns}
                        data={dataWithPrice || []}
                        loading={pricingApprovalLoading}
                    />
                </div>
                <Table
                    columns={columns}
                    data={dataWithPrice || []}
                    loading={pricingApprovalLoading}
                />
                <div className="w-fit">
                    <Table
                        columns={fixedTableColumns}
                        data={dataWithPrice || []}
                        loading={pricingApprovalLoading}
                    />
                </div>
            </div>
            <form
                onSubmit={formik.handleSubmit}
                className="mt-8 flex items-end max-w-5xl flex-col gap-4"
            >
                <Select
                    horizontal
                    options={deliveryCostOptions || []}
                    label={t('fields.delivery_cost')}
                    selectWidth="w-[24rem]"
                    name="deliveryCost"
                    showPlaceHolder
                    placeHolderOption={t('fields.select_delivery_option')}
                    value={formik.values.deliveryCost}
                    onChange={e => formik.setFieldValue('deliveryCost', e.target.value)}
                    error={formik.touched.deliveryCost ? formik.errors.deliveryCost : undefined}
                />
                {formik.values.deliveryCost === 'other_value' && (
                    <Input
                        horizontal
                        label={t('fields.delivery_cost_other')}
                        name="deliveryCostOther"
                        inputWidth="w-[24rem]"
                        customInputStyles="p-0"
                        value={formik.values.deliveryCostOther}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.deliveryCostOther
                                ? formik.errors.deliveryCostOther
                                : undefined
                        }
                    />
                )}
                <Select
                    horizontal
                    options={contractCostOptions || []}
                    label={t('fields.contract_cost')}
                    name="contractCost"
                    selectWidth="w-[24rem]"
                    showPlaceHolder
                    placeHolderOption={t('fields.select_contract_option')}
                    value={formik.values.contractCost}
                    onChange={e => {
                        const { value } = e.target
                        const availableValue = authorizationMatrix?.find(
                            (auth: any) => auth.authorization_condition === 'CONTRACT_COST',
                        )?.value
                        const isAdjustable = authorizationMatrix?.find(
                            (auth: any) => auth.authorization_condition === 'CONTRACT_COST',
                        )?.adjustable
                        if (
                            isAdjustable ||
                            parseFloat(value) === parseFloat(String(availableValue))
                        ) {
                            formik.setFieldValue('contractCost', e.target.value)
                        } else {
                            toast.error(t('message.not_authorized'))
                        }
                    }}
                    error={formik.touched.contractCost ? formik.errors.contractCost : undefined}
                />
                {formik.values.contractCost === 'other_value' && (
                    <Input
                        horizontal
                        label={t('fields.contract_cost_other')}
                        name="contractCostOther"
                        inputWidth="w-[24rem]"
                        customInputStyles="p-0"
                        value={formik.values.contractCostOther}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.contractCostOther
                                ? formik.errors.contractCostOther
                                : undefined
                        }
                    />
                )}
                <Select
                    horizontal
                    options={debtCollectionOptions || []}
                    label={t('fields.non_automatic_debt_collection_fees')}
                    name="noAutomaticDebtCollectionFee"
                    showPlaceHolder
                    selectWidth="w-[24rem]"
                    placeHolderOption={t('fields.select_non_automatic_debt_collection_fees')}
                    value={formik.values.noAutomaticDebtCollectionFee}
                    onChange={e => {
                        const { value } = e.target
                        const availableValue = authorizationMatrix?.find(
                            (auth: any) =>
                                auth.authorization_condition ===
                                'NON_AUTOMATIC_DEBT_COLLECTION_FEES',
                        )?.value
                        const isAdjustable = authorizationMatrix?.find(
                            (auth: any) =>
                                auth.authorization_condition ===
                                'NON_AUTOMATIC_DEBT_COLLECTION_FEES',
                        )?.adjustable
                        if (
                            isAdjustable ||
                            parseFloat(String(availableValue)) === parseFloat(String(value))
                        ) {
                            formik.setFieldValue('noAutomaticDebtCollectionFee', e.target.value)
                        } else {
                            toast.error(t('message.not_authorized'))
                        }
                    }}
                    error={
                        formik.touched.noAutomaticDebtCollectionFee
                            ? formik.errors.noAutomaticDebtCollectionFee
                            : undefined
                    }
                />
                {formik.values.noAutomaticDebtCollectionFee === 'other_value' && (
                    <Input
                        horizontal
                        label={t('fields.non_automatic_debt_collection_fees_other')}
                        name="noAutomaticDebtCollectionFeeOther"
                        inputWidth="w-[24rem]"
                        customInputStyles="p-0"
                        value={formik.values.noAutomaticDebtCollectionFeeOther}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.noAutomaticDebtCollectionFeeOther
                                ? formik.errors.noAutomaticDebtCollectionFeeOther
                                : undefined
                        }
                    />
                )}
                {!orderId && (
                    <div className="w-fit">
                        <Button
                            label={t('api.save')}
                            isLoading={editQuoteInPricingApprovalMutation.isLoading}
                            disabled={editQuoteInPricingApprovalMutation.isLoading}
                            variant="outline"
                            type="submit"
                        />
                    </div>
                )}
            </form>
            <Modal
                open={showSendEmailModal}
                center
                onClose={() => setShowSendEmailModal(false)}
                showCloseIcon={false}
                classNames={{
                    root: 'rounded-lg',
                    modal: 'rounded-lg w-[80%]',
                }}
            >
                <p className="text-xl">{t('api.request_approval_emails')}</p>
                <p className="text-red-700 text-sm">
                    {t('api.notes')} :{' '}
                    {t('message.sending_email_will_change_status_of_all_configurations')}
                </p>
                <div className="my-2">
                    <TextArea
                        value={remark}
                        onChange={event => setRemark(event.target.value)}
                        name="remark"
                        label={t('fields.remark')}
                        cols={2}
                    />
                </div>
                <Table
                    columns={emailColumns}
                    data={approvalEmailOptions || []}
                    loading={emailLoading}
                />
            </Modal>
        </div>
    )
}

export default PricingApproval
