/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unstable-nested-components */
import { Collapse } from 'antd'
import classNames from 'classnames'
import Button from 'components/button'
import Loading from 'components/loading'
import { extend, groupBy, keys, map, omit } from 'lodash'
import AddExtraQuote from 'modules/quotes/views/AddExtraQuote'
import { useState } from 'react'
import { GrClose } from 'react-icons/gr'
import { IoChevronBack } from 'react-icons/io5'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { formatCurrency } from 'utils/currency/formatCurrency'
import { errorToast, successToast } from 'utils/toasterUtil'
import Table from '../../../components/table'
import PanelHeader from '../../configuration/components/panelHeader'
import useTranslationData from '../../translation/hooks/useTranslationData'
import NovoConfgInfoCard from '../components/NovoConfgInfoCard'
import {
    getNovoConfigurationById,
    getNovoConfigurationVersionDetail,
    updateNovoConfiguration,
} from '../query'

type CUSTOMTYPE = {
    [key: string | number]: string | number
}
const { Panel } = Collapse

interface NovoDetailProps {
    novoVersionId?: string
    novoIdProp?: string
    goBackRoute?: string
    hideBack?: boolean
}

const NovoDetail = (props: NovoDetailProps) => {
    const {
        translation: { t },
    } = useTranslationData()
    const queryClient = useQueryClient()
    const { novoVersionId, novoIdProp, goBackRoute, hideBack } = props

    const { novoId } = useParams()
    const [quoteData, setQuoteData] = useState<any>({})
    const [novoData, setNovoData] = useState<any>({})
    const [originalData, setOriginalData] = useState<any>({})
    const [showOptional, setShowOptional] = useState<boolean>(false)
    const [optionalData, setOptionalData] = useState<any>({} as any)
    const [novoConfigurationExtraData, setNovoConfigurationExtraData] = useState<any>({} as any)

    const columns: any = [
        {
            id: 'part_number',
            name: t('api.part_number'),
            left: true,
            height: 'fit-content',
            wrap: true,
            width: '200px',
            selector: (row: CUSTOMTYPE) => {
                return <p className="text-md text-left">{row['Part Number'] || ''}</p>
            },
        },
        {
            id: 'description',
            name: t('api.part_description'),
            left: true,
            height: 'fit-content',
            wrap: true,
            selector: (row: CUSTOMTYPE) => {
                return <p className="text-md text-left py-2">{row['Part Description'] || ''}</p>
            },
        },
        {
            id: 'quantity',
            name: t('api.quantity'),
            center: true,
            height: 'fit-content',
            wrap: true,
            width: '120px',
            selector: (row: CUSTOMTYPE) => {
                return <p className="text-md text-left">{row?.Quantity}</p>
            },
        },
        {
            id: 'discount',
            name: t('api.discount'),
            center: true,
            height: 'fit-content',
            wrap: true,
            width: '120px',
            selector: (row: CUSTOMTYPE) => {
                return <p className="text-md text-left">{row?.Discount}</p>
            },
        },
        {
            id: 'list_price',
            name: t('api.list_price'),
            center: true,
            height: 'fit-content',
            wrap: true,
            width: '200px',
            selector: (row: CUSTOMTYPE) => {
                return <p className="text-md text-left">{formatCurrency(row['List Price'] || 0)}</p>
            },
        },
        {
            id: 'net_price',
            name: t('api.net_price_each'),
            center: true,
            height: 'fit-content',
            wrap: true,
            width: '200px',
            selector: (row: CUSTOMTYPE) => {
                return (
                    <p className="text-md text-left">
                        {formatCurrency(row['Net Price Each'] || 0)}
                    </p>
                )
            },
        },
    ]

    const mappedData = (configurationData: any) => {
        const parsedData = JSON.parse(configurationData.data)
        const optionalNovo: any = []
        const nonOptionalNovo: any = []

        map(parsedData, item => {
            if (item.Optional === 'Yes') {
                optionalNovo.push(item)
            } else {
                nonOptionalNovo.push(item)
            }
        })

        setQuoteData(groupBy(nonOptionalNovo, 'Included Items') || {})
        setOptionalData(groupBy(optionalNovo, 'Included Items') || {})
        // for restoring original data to update data.
        setNovoData(parsedData)
    }

    const { isLoading, isFetching, refetch } = useQuery(
        ['getNovoConfigurationById', novoVersionId, novoId || novoIdProp],
        () => {
            if (novoVersionId) {
                return getNovoConfigurationVersionDetail(
                    parseInt(novoId || novoIdProp || '0', 10),
                    parseInt(novoVersionId || '0', 10),
                )
            }
            return getNovoConfigurationById(parseInt(novoId || novoIdProp || '0', 10))
        },
        {
            enabled: !!(novoId || novoIdProp) || !!novoVersionId,
            onSuccess: data => {
                const configurationData = data?.data?.data.novo_configuration_data || {
                    data: '[]',
                }
                mappedData(configurationData)
                setOriginalData(configurationData || {})
                setNovoConfigurationExtraData(data?.data?.data)
            },
        },
    )

    const updateNovoConfigurationMutation = useMutation(
        ['updateNovoConfiguration', originalData?.id],
        (data: any) => updateNovoConfiguration(originalData?.id || '', data),
        {
            onSuccess: res => {
                queryClient.invalidateQueries('getPricingInitialData')
                refetch()
                toast(
                    res?.data?.message || `${t('message.update_novo_configuration_success')}`,
                    successToast,
                )
            },
            onError: (error: { message: string }) => {
                refetch()
                toast(
                    error?.message || `${t('message.update_novo_configuration_fail')}`,
                    errorToast,
                )
            },
        },
    )

    const handleConfiguration = (checkedValue: string, data: any) => {
        const mappedDataItem = map(novoData, (item: any) => {
            if (
                item['Part Number'] === data['Part Number'] &&
                item['Included Items'] === data['Included Items']
            ) {
                return extend(item, {
                    Optional: checkedValue,
                    selected: true,
                })
            }
            return item
        })

        const updatedData = extend(originalData, {
            data: JSON.stringify(mappedDataItem),
        })

        mappedData(updatedData)
        setOriginalData(updatedData)
        updateNovoConfigurationMutation.mutate(originalData)
    }

    const handleSelectOption = (checkedValue: boolean, data: any) => {
        const mappedDataItem = map(novoData, (item: any) => {
            if (
                item['Part Number'] === data['Part Number'] &&
                item['Included Items'] === data['Included Items']
            ) {
                return extend(item, {
                    selected: checkedValue,
                })
            }
            return item
        })

        const updatedData = extend(originalData, {
            data: JSON.stringify(mappedDataItem),
        })

        mappedData(updatedData)
        setOriginalData(updatedData)
        updateNovoConfigurationMutation.mutate(originalData)
    }

    if (isLoading || isFetching) {
        return (
            <div className="h-[84vh] flex w-full justify-center items-center">
                <Loading />
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-8 mt-4 mr-4">
            {!hideBack && (
                <a
                    href={goBackRoute || '/novo-configuration'}
                    className="flex items-center gap-1 text-lg font-medium"
                >
                    <span>
                        <IoChevronBack size={20} />
                    </span>
                    <span>{t('api.back')}</span>
                </a>
            )}
            <NovoConfgInfoCard
                isLithium={novoConfigurationExtraData?.lithium_battery}
                isDeleted={novoConfigurationExtraData?.is_deleted}
                machine={(quoteData && quoteData?.Model && quoteData?.Model[0]) || {}}
                handleOptional={() => setShowOptional(!showOptional)}
                showOptional={showOptional}
                hideButton={Boolean(novoVersionId)}
                versionData={novoConfigurationExtraData}
            />
            <div className="flex flex-col items-start gap-6 w-full">
                <div className="w-full">
                    <h3 className="text-secondary border-primary text-lg font-medium underline underline-offset-8 decoration-4 decoration-primary">
                        {t('api.parts')}
                    </h3>
                    <Collapse
                        style={{ backgroundColor: 'transparent' }}
                        className="w-full"
                        defaultActiveKey={Object.keys(omit(quoteData, ['Model']))}
                    >
                        {map(omit(quoteData, ['Model']), (value, key) => {
                            return (
                                <Panel key={key} header={PanelHeader({ title: key })}>
                                    <Table
                                        columns={[
                                            {
                                                id: 'option',
                                                name: t('api.add_manual_adjustment_optional'),
                                                center: true,
                                                height: 'fit-content',
                                                wrap: true,
                                                width: '220px',
                                                omit: !showOptional,
                                                cell: (row: CUSTOMTYPE) => {
                                                    return (
                                                        <div className="w-2/3 py-2">
                                                            <Button
                                                                label="Mark as Optional"
                                                                variant="outline"
                                                                onClick={() =>
                                                                    handleConfiguration('Yes', row)
                                                                }
                                                            />
                                                        </div>
                                                    )
                                                },
                                            },
                                            ...columns,
                                        ]}
                                        data={value || []}
                                        pagination={false}
                                        striped={false}
                                    />
                                </Panel>
                            )
                        })}
                    </Collapse>
                </div>
                <div className="w-full flex flex-col gap-4">
                    <h3 className="text-secondary border-primary text-lg font-medium underline underline-offset-8 decoration-4 decoration-primary">
                        {t('api.optional_parts')}
                    </h3>
                    {keys(optionalData).length !== 0 ? (
                        <Collapse
                            className="w-full"
                            style={{ backgroundColor: 'transparent' }}
                            defaultActiveKey={map(
                                omit(optionalData, ['Model']),
                                (value, key) => key,
                            )}
                        >
                            {map(omit(optionalData, ['Model']), (value, key) => (
                                <Panel key={key} header={PanelHeader({ title: key })}>
                                    <Table
                                        columns={[
                                            {
                                                id: 'option',
                                                name: t('api.remove_manual_adjustment_optional'),
                                                center: true,
                                                height: 'fit-content',
                                                wrap: true,
                                                width: '220px',
                                                omit: !showOptional,
                                                cell: (row: CUSTOMTYPE) => {
                                                    if (row.Optional === 'Yes') {
                                                        return (
                                                            <div className="w-2/3 py-2">
                                                                <Button
                                                                    label={
                                                                        row.selected
                                                                            ? t('api.de_select')
                                                                            : t('api.select')
                                                                    }
                                                                    variant={
                                                                        row.selected
                                                                            ? 'error'
                                                                            : 'outline'
                                                                    }
                                                                    onClick={() =>
                                                                        handleSelectOption(
                                                                            !row.selected,
                                                                            row,
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                    return null
                                                },
                                            },
                                            {
                                                id: 'optional-selected',
                                                name: t('api.optional_selected'),
                                                left: true,
                                                height: 'fit-content',
                                                wrap: true,
                                                width: '200px',
                                                cell: (row: CUSTOMTYPE) => {
                                                    if (row.Optional === 'No' && !row.selected) {
                                                        return (
                                                            <div>
                                                                <p className="text-md">
                                                                    {t('api.selected')}
                                                                </p>
                                                            </div>
                                                        )
                                                    }
                                                    return (
                                                        <div>
                                                            <div
                                                                className={classNames(
                                                                    'border-2 rounded-full py-1 px-4 text-md',
                                                                    row.selected
                                                                        ? 'border-accent'
                                                                        : 'border-primary',
                                                                )}
                                                            >
                                                                {row.selected
                                                                    ? t('api.selected')
                                                                    : t('api.not_selected')}
                                                            </div>
                                                        </div>
                                                    )
                                                },
                                            },
                                            ...columns,
                                            {
                                                id: 'action',
                                                name: t('api.remove_manual_adjustment_optional'),
                                                center: true,
                                                height: 'fit-content',
                                                wrap: true,
                                                width: '220px',
                                                omit: !showOptional,
                                                cell: (row: CUSTOMTYPE) => {
                                                    if (row.Optional === 'Yes') {
                                                        return (
                                                            <div className="w-2/3 py-2">
                                                                <Button
                                                                    customButtonClass="hover:bg-red-500"
                                                                    title={t('api.make_required')}
                                                                    variant="ghost"
                                                                    leftIcon={<GrClose size={25} />}
                                                                    onClick={() =>
                                                                        handleConfiguration(
                                                                            'No',
                                                                            row,
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                    return null
                                                },
                                            },
                                        ]}
                                        data={value || []}
                                        pagination={false}
                                        striped={false}
                                    />
                                </Panel>
                            ))}
                        </Collapse>
                    ) : (
                        <h3 className="text-md text-bold">{t('api.no_optional_parts')}</h3>
                    )}
                </div>
                <div className="w-full flex flex-col gap-4">
                    <h3 className="text-secondary border-primary text-lg font-medium underline underline-offset-8 decoration-4 decoration-primary">
                        {t('api.extra_parts')}
                    </h3>
                    <AddExtraQuote configuration={novoId || novoIdProp} viewOnly />
                </div>
            </div>
        </div>
    )
}

export default NovoDetail
