import { Form, Input, Select } from 'antd'
import { map } from 'lodash'
import { callAxios } from 'plugins/api'
import React, { useState } from 'react'
import { useQueries } from 'react-query'

import Button from 'components/button'
import { useAuth } from 'modules/auth/context/useAuth'
import { useTranslation } from 'react-i18next'
import Loading from '../../../../components/loading'

interface QuoteInformationProps {
    formik: any
}

const roundedClass = 'rounded-lg flex-1'
const size = 'large'

const QuoteInformation = (props: QuoteInformationProps) => {
    const { formik } = props
    const [users, setUserList] = useState<[]>([])
    const { userExtra } = useAuth()
    const { t } = useTranslation()

    const quoteInfos = useQueries([
        {
            queryKey: ['quoteInfo'],
            enabled: !formik.values.quotation_name,
            queryFn: () =>
                callAxios({
                    method: 'get',
                    url: 'v2/quote/new-title',
                }),
            onSuccess: (data: any) => {
                const name = data?.data?.data || `NLV-QUO-${parseInt(data.data[0].id + 1, 10)}`
                formik.setFieldValue('quotation_name', name)
            },
        },
        {
            queryKey: ['user_list'],
            queryFn: () =>
                callAxios({
                    method: 'get',
                    url: 'auth/users',
                }),
            onSuccess: (data: any) => {
                const userList = data?.data.map(
                    (user: { name: string; id: string; email: string; role_name: string }) => {
                        return {
                            label: `${user.name} | ${user.role_name} | [${user.email}]`,
                            value: user.id,
                        }
                    },
                )
                setUserList(userList)
            },
        },
    ])

    const isLoading = quoteInfos.some(result => result.isLoading)
    if (isLoading) {
        return (
            <div className="flex justify-center items-center bg-white px-8 py-4 rounded-xl gap-4">
                <Loading />
            </div>
        )
    }

    return (
        <div className="bg-white h-full w-full py-8 px-8 rounded-lg flex justify-between flex-col gap-4">
            <div>
                <h1 className="text-3xl text-center">{t('api.quote_information')}</h1>
                <div className="flex flex-col justify-center align-center items-center">
                    <Form.Item
                        name="quotation_name"
                        className="flex flex-col w-[45%]"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <div>
                            <h5 className="capitalize">{t('api.quote_name')}</h5>
                        </div>
                        <Input
                            name="quotation_name"
                            value={formik.values.quotation_name}
                            onChange={formik.handleChange}
                            disabled
                            placeholder="Enter quotation name"
                            size={size}
                            className={roundedClass}
                        />
                        {formik.errors &&
                            formik.errors.quotation_name &&
                            formik.touched.quotation_name && (
                                <p className="text-red-600 mt-1">{formik.errors.quotation_name}</p>
                            )}
                    </Form.Item>
                    {/* {userExtra.role_id === 4 && (
                        <Form.Item
                            name="user"
                            className="flex flex-col w-[45%]"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]}
                        >
                            <div>
                                <h5 className="capitalize">{t('api.user')}</h5>
                            </div>
                            <Select
                                defaultValue={t('api.choose_user')}
                                onChange={(value: string) => formik.setFieldValue('user', value)}
                                className="rounded-lg"
                                size={size}
                            >
                                {map(users || [], (option: any) => (
                                    <Select.Option value={option.value}>
                                        {option.label}
                                    </Select.Option>
                                ))}
                            </Select>
                            {formik.errors && formik.errors.user && formik.touched.user && (
                                <p className="text-red-600 mt-1">{formik.errors.user}</p>
                            )}
                        </Form.Item>
                    )} */}
                    <Form.Item
                        name="quotation_name"
                        className="flex flex-col w-[45%]"
                        rules={[
                            {
                                required: true,
                                message: t('api.required'),
                            },
                        ]}
                    >
                        <div>
                            <h5 className="capitalize">
                                {t('fields.customer_name_quotation_for')}
                            </h5>
                        </div>
                        <Input
                            name="quotation_for"
                            value={formik.values.quotation_for}
                            onChange={formik.handleChange}
                            placeholder={t('fields.enter_customer_name')}
                            size={size}
                            className={roundedClass}
                        />
                        {formik.errors &&
                            formik.errors.quotation_for &&
                            formik.touched.quotation_for && (
                                <p className="text-red-600 mt-1">{formik.errors.quotation_for}</p>
                            )}
                    </Form.Item>
                </div>
            </div>
            <div className="w-full flex justify-end">
                <div className="w-48">
                    <Button
                        variant="primary"
                        type="submit"
                        label={t('api.next')}
                        isLoading={false}
                        onClick={() => formik.handleSubmit()}
                    />
                </div>
            </div>
        </div>
    )
}

export default QuoteInformation
