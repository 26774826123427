const HOME_ROUTE = '/'
const PROFILE_ROUTE = '/profile'
const DISCOUNT_REQUEST_ROUTE = '/discount-request'
const DISCOUNT_HISTORY_ROUTE = '/discount-history'
const CUSTOMER_FEEDBACK_ROUTE = '/customer-feedback'
const BULLETIN_ROUTE = '/bulletin'
const PRIVACY_ROUTE = '/privacy'
const POLICY_ROUTE = '/policy'
const DISCLAIMER_ROUTE = '/disclaimer'

const REQUEST_TS = '/request-ts'

// auth
const LOGIN_ROUTE = '/auth/login'
const FORGET_PASSWORD_ROUTE = '/auth/forget-password'

const NOVO_CONFIGURATION_ROUTE = '/novo-configuration'
const USED_TRUCK_ROUTE = '/used-truck'
// const RESET_PASSWORD_ROUTE = '/auth/password-reset/:resetToken'

// dashboard
export {
    HOME_ROUTE,
    PROFILE_ROUTE,
    DISCOUNT_REQUEST_ROUTE,
    DISCOUNT_HISTORY_ROUTE,
    CUSTOMER_FEEDBACK_ROUTE,
    BULLETIN_ROUTE,
    LOGIN_ROUTE,
    FORGET_PASSWORD_ROUTE,
    DISCLAIMER_ROUTE,
    POLICY_ROUTE,
    PRIVACY_ROUTE,
    NOVO_CONFIGURATION_ROUTE,
    REQUEST_TS,
    USED_TRUCK_ROUTE,
}
