import { FormikProps } from 'formik'
import { Collapse } from 'antd'
import { ISiteSurveyForm } from './AddSiteSurvey'
import Select, { Options } from '../../../components/select'
import Input from '../../../components/input'
import TextArea from '../../../components/textArea'
import {
    branchOption,
    dockShelterOrRampsOptions,
    floorConditionOption,
    floorOptions,
    FuelTypeOptions,
    indoorOrOutdoorOptions,
    palletSizeOptions,
    seasonalCrowdOption,
    workingEnvironmentTemperatureOptions,
    workInShiftOptions,
} from './SiteSurveyOptions'
import useTranslationData from '../../translation/hooks/useTranslationData'
import { yesNoOptions } from './siteSurveyDropDown'

interface IMachineDescriptions {
    formik: FormikProps<ISiteSurveyForm>
    machineSegmentOption: Options[]
    machineSeriesOption: Options[]
    machineModelOption: Options[]
    showNonRequiredFields: boolean
    secondFormCollapseActiveKey: string[]
    setSecondFormCollapseActiveKey: (value: string[]) => void
}

const MachineDescriptions = (props: IMachineDescriptions) => {
    const {
        formik,
        machineModelOption,
        machineSegmentOption,
        machineSeriesOption,
        setSecondFormCollapseActiveKey,
        showNonRequiredFields,
        secondFormCollapseActiveKey,
    } = props

    const { Panel } = Collapse
    const {
        translation: { t },
    } = useTranslationData()

    return (
        <div className="flex flex-col gap-4">
            <Collapse
                ghost
                accordion
                expandIconPosition="right"
                className="bg-white w-full"
                activeKey={secondFormCollapseActiveKey}
                onChange={(key: any) => {
                    setSecondFormCollapseActiveKey(key)
                }}
            >
                <Panel
                    className="bg-white"
                    header={<p className="text-lg">{t('api.surrounding_and_deployment')}</p>}
                    key="1"
                >
                    <div className="p-4 grid grid-cols-1 gap-4">
                        <Input
                            horizontal
                            value={formik.values.locationAndDepartment}
                            name="locationAndDepartment"
                            onChange={formik.handleChange}
                            type="text"
                            required
                            error={
                                formik.touched.locationAndDepartment
                                    ? formik.errors.locationAndDepartment
                                    : undefined
                            }
                            label={t('fields.location_and_department')}
                            placeholder={`${t('fields.enter')} ${t(
                                'fields.location_and_department',
                            )}`}
                        />
                        <Select
                            value={formik.values.branch}
                            horizontal
                            onChange={item => formik.setFieldValue('branch', item.target.value)}
                            options={branchOption}
                            placeHolderOption="Select Branch"
                            showPlaceHolder
                            required
                            name="branch"
                            label={t('fields.branch')}
                            error={formik.touched.branch ? formik.errors.branch : undefined}
                        />
                        {formik.values.branch === 'other' && (
                            <Input
                                horizontal
                                value={formik.values.branchOther}
                                name="branchOther"
                                onChange={formik.handleChange}
                                type="text"
                                error={
                                    formik.touched.branchOther
                                        ? formik.errors.branchOther
                                        : undefined
                                }
                                label={t('fields.location_and_department')}
                                placeholder={`${t('fields.enter')} ${t(
                                    'fields.location_and_department',
                                )}`}
                            />
                        )}
                        <Select
                            value={formik.values.floor}
                            horizontal
                            onChange={item => formik.setFieldValue('floor', item.target.value)}
                            options={floorOptions}
                            required
                            placeHolderOption={`${t('fields.select')} ${t('fields.floor')}`}
                            showPlaceHolder
                            name="floor"
                            label={t('fields.floor')}
                            error={formik.touched.floor ? formik.errors.floor : undefined}
                        />
                        <Select
                            value={formik.values.floorCondition}
                            horizontal
                            onChange={item =>
                                formik.setFieldValue('floorCondition', item.target.value)
                            }
                            options={floorConditionOption}
                            placeHolderOption={`${t('fields.select')} ${t(
                                'fields.floor_condition',
                            )}`}
                            showPlaceHolder
                            required
                            name="floorCondition"
                            label={t('fields.floor_condition')}
                            error={
                                formik.touched.floorCondition
                                    ? formik.errors.floorCondition
                                    : undefined
                            }
                        />
                        <Input
                            value={formik.values.forkClearance}
                            name="forkClearance"
                            horizontal
                            onChange={formik.handleChange}
                            placeholder={`${t('fields.enter')} ${t('fields.fork_clearance')}`}
                            type="text"
                            required
                            error={
                                formik.touched.forkClearance
                                    ? formik.errors.forkClearance
                                    : undefined
                            }
                            label={t('fields.fork_clearance')}
                        />
                        <Input
                            value={formik.values.outletHeightForPallet}
                            name="outletHeightForPallet"
                            horizontal
                            onChange={formik.handleChange}
                            type="text"
                            required
                            error={
                                formik.touched.outletHeightForPallet
                                    ? formik.errors.outletHeightForPallet
                                    : undefined
                            }
                            label={t('fields.outlet_height_for_pallet')}
                            placeholder={`${t('fields.enter')} ${t(
                                'fields.outlet_height_for_pallet',
                            )}`}
                        />
                        <Select
                            value={formik.values.dockShelters}
                            onChange={item =>
                                formik.setFieldValue('dockShelters', item.target.value)
                            }
                            options={dockShelterOrRampsOptions}
                            horizontal
                            required
                            placeHolderOption={`${t('fields.select')} ${t('fields.dock_shelters')}`}
                            showPlaceHolder
                            name="dockShelters"
                            label={t('fields.dock_shelters')}
                            error={
                                formik.touched.dockShelters ? formik.errors.dockShelters : undefined
                            }
                        />
                        <Select
                            value={formik.values.ramps}
                            onChange={item => formik.setFieldValue('ramps', item.target.value)}
                            options={dockShelterOrRampsOptions}
                            horizontal
                            required
                            placeHolderOption={`${t('fields.select')} ${t('fields.ramps')}`}
                            showPlaceHolder
                            name="ramps"
                            label={t('fields.ramps')}
                            error={formik.touched.ramps ? formik.errors.ramps : undefined}
                        />
                        <Input
                            value={formik.values.runningHours}
                            name="runningHours"
                            onChange={formik.handleChange}
                            type="number"
                            required
                            horizontal
                            error={
                                formik.touched.runningHours ? formik.errors.runningHours : undefined
                            }
                            rightContent={<p>{t('api.hrs')}</p>}
                            label={t('fields.running_hours')}
                            placeholder={`${t('fields.enter')} ${t('fields.running_hours')}`}
                        />
                        <Select
                            value={formik.values.workInShifts}
                            onChange={item =>
                                formik.setFieldValue('workInShifts', item.target.value)
                            }
                            horizontal
                            options={workInShiftOptions}
                            placeHolderOption={`${t('fields.select')} ${t(
                                'fields.work_in_shifts',
                            )}`}
                            showPlaceHolder
                            required
                            name="workInShifts"
                            label={t('fields.work_in_shifts')}
                            error={
                                formik.touched.workInShifts ? formik.errors.workInShifts : undefined
                            }
                        />
                        {showNonRequiredFields && (
                            <>
                                <Select
                                    value={formik.values.indoorOrOutDoor}
                                    horizontal
                                    onChange={item =>
                                        formik.setFieldValue('indoorOrOutDoor', item.target.value)
                                    }
                                    options={indoorOrOutdoorOptions}
                                    placeHolderOption={`${t('fields.select')} ${t(
                                        'fields.indoor_or_outdoor',
                                    )}`}
                                    showPlaceHolder
                                    name="indoorOrOutDoor"
                                    label={t('fields.indoor_or_outdoor')}
                                    error={
                                        formik.touched.indoorOrOutDoor
                                            ? formik.errors.indoorOrOutDoor
                                            : undefined
                                    }
                                />
                                <Select
                                    value={formik.values.workingEnvironmentTemperature}
                                    horizontal
                                    onChange={item =>
                                        formik.setFieldValue(
                                            'workingEnvironmentTemperature',
                                            item.target.value,
                                        )
                                    }
                                    options={workingEnvironmentTemperatureOptions}
                                    placeHolderOption={`${t('fields.select')} ${t(
                                        'fields.working_environment_temperature',
                                    )}`}
                                    showPlaceHolder
                                    name="workingEnvironmentTemperature"
                                    label={t('fields.working_environment_temperature')}
                                    error={
                                        formik.touched.workingEnvironmentTemperature
                                            ? formik.errors.workingEnvironmentTemperature
                                            : undefined
                                    }
                                />
                                <Input
                                    value={formik.values.corridorWidth}
                                    name="corridorWidth"
                                    horizontal
                                    onChange={formik.handleChange}
                                    type="text"
                                    error={
                                        formik.touched.corridorWidth
                                            ? formik.errors.corridorWidth
                                            : undefined
                                    }
                                    label={t('fields.corridor_width')}
                                    placeholder={`${t('fields.enter')} ${t(
                                        'fields.corridor_width',
                                    )}`}
                                />
                                <Select
                                    value={formik.values.seasonalCrowds ? 'yes' : 'no'}
                                    onChange={item =>
                                        formik.setFieldValue(
                                            'seasonalCrowds',
                                            item.target.value === 'yes',
                                        )
                                    }
                                    horizontal
                                    options={seasonalCrowdOption}
                                    placeHolderOption={`${t('fields.select')} ${t(
                                        'fields.seasonal_crowds',
                                    )}`}
                                    showPlaceHolder
                                    name="seasonalCrowds"
                                    label={t('fields.seasonal_crowds')}
                                    error={
                                        formik.touched.seasonalCrowds
                                            ? formik.errors.seasonalCrowds
                                            : undefined
                                    }
                                />
                                <TextArea
                                    horizontal
                                    value={formik.values.surroundingAdditionalInformation}
                                    name="surroundingAdditionalInformation"
                                    onChange={formik.handleChange}
                                    rows={3}
                                    error={
                                        formik.touched.surroundingAdditionalInformation
                                            ? formik.errors.surroundingAdditionalInformation
                                            : undefined
                                    }
                                    label={t('fields.additional_information')}
                                    placeholder={`${t('fields.enter')} ${t(
                                        'fields.additional_information',
                                    )}`}
                                />
                            </>
                        )}
                    </div>
                </Panel>
                <Panel
                    key="2"
                    className="bg-white"
                    header={<p className="text-lg">{t('api.load_for_the_forklift_truck')}</p>}
                >
                    <div className="p-4 grid grid-cols-1 gap-4">
                        <Input
                            value={formik.values.weight}
                            name="weight"
                            horizontal
                            required
                            onChange={formik.handleChange}
                            type="text"
                            error={formik.touched.weight ? formik.errors.weight : undefined}
                            label={t('fields.weight')}
                            placeholder={`${t('fields.enter')} ${t('fields.weight')}`}
                        />
                        <Input
                            value={formik.values.loadCenter}
                            name="loadCenter"
                            horizontal
                            required
                            onChange={formik.handleChange}
                            type="text"
                            error={formik.touched.loadCenter ? formik.errors.loadCenter : undefined}
                            label={t('fields.load_center')}
                            placeholder={`${t('fields.enter')} ${t('fields.load_center')}`}
                        />
                        {showNonRequiredFields && (
                            <>
                                {formik.values.overBuildEquipmentOnSite && (
                                    <Input
                                        value={formik.values.overBuildEquipmentOnSiteRemark}
                                        name="overBuildEquipmentOnSiteRemark"
                                        horizontal
                                        onChange={formik.handleChange}
                                        type="text"
                                        error={
                                            formik.touched.overBuildEquipmentOnSiteRemark
                                                ? formik.errors.overBuildEquipmentOnSiteRemark
                                                : undefined
                                        }
                                        label={t('fields.overbuild_equipment_on_site_remark')}
                                        placeholder={`${t('fields.enter')} ${t(
                                            'fields.overbuild_equipment_on_site_remark',
                                        )}`}
                                    />
                                )}
                                <Select
                                    value={formik.values.palletSize}
                                    onChange={item =>
                                        formik.setFieldValue('palletSize', item.target.value)
                                    }
                                    horizontal
                                    options={palletSizeOptions}
                                    placeHolderOption={`${t('fields.select')} ${t(
                                        'fields.pallet_size',
                                    )}`}
                                    showPlaceHolder
                                    name="palletSize"
                                    label={t('fields.pallet_size')}
                                    error={
                                        formik.touched.palletSize
                                            ? formik.errors.palletSize
                                            : undefined
                                    }
                                />
                                {formik.values.palletSize === 'other' && (
                                    <Input
                                        value={formik.values.palletSizeOther}
                                        name="palletSizeOther"
                                        onChange={formik.handleChange}
                                        type="text"
                                        horizontal
                                        error={
                                            formik.touched.palletSizeOther
                                                ? formik.errors.palletSizeOther
                                                : undefined
                                        }
                                        label={t('fields.pallet_size_other')}
                                        placeholder={`${t('fields.enter')} ${t(
                                            'fields.pallet_size',
                                        )}`}
                                    />
                                )}
                                <Input
                                    value={formik.values.loadDimension}
                                    name="loadDimension"
                                    onChange={formik.handleChange}
                                    type="text"
                                    horizontal
                                    error={
                                        formik.touched.loadDimension
                                            ? formik.errors.loadDimension
                                            : undefined
                                    }
                                    label={t('fields.load_dimension')}
                                    placeholder={`${t('fields.enter')} ${t(
                                        'fields.load_dimension',
                                    )}`}
                                />
                                <Input
                                    value={formik.values.material}
                                    name="material"
                                    horizontal
                                    onChange={formik.handleChange}
                                    type="text"
                                    error={
                                        formik.touched.material ? formik.errors.material : undefined
                                    }
                                    label={t('fields.material')}
                                    placeholder={`${t('fields.enter')} ${t('fields.material')}`}
                                />
                                <Input
                                    value={formik.values.stackingPackage}
                                    name="stackingPackage"
                                    horizontal
                                    onChange={formik.handleChange}
                                    type="text"
                                    error={
                                        formik.touched.stackingPackage
                                            ? formik.errors.stackingPackage
                                            : undefined
                                    }
                                    label={t('fields.stacking_package')}
                                    placeholder={`${t('fields.enter')} ${t(
                                        'fields.stacking_package',
                                    )}`}
                                />
                                <TextArea
                                    horizontal
                                    value={formik.values.loadAdditionalInformation}
                                    name="loadAdditionalInformation"
                                    onChange={formik.handleChange}
                                    rows={3}
                                    error={
                                        formik.touched.loadAdditionalInformation
                                            ? formik.errors.loadAdditionalInformation
                                            : undefined
                                    }
                                    label={t('fields.additional_information')}
                                    placeholder={`${t('fields.enter')} ${t(
                                        'fields.additional_information',
                                    )}`}
                                />
                            </>
                        )}
                    </div>
                </Panel>
                <Panel
                    key="3"
                    className="bg-white"
                    header={<p className="text-lg">{t('api.truck_model_and_specification')}</p>}
                >
                    <div className="p-4 grid grid-cols-1 gap-4">
                        <Select
                            horizontal
                            value={formik.values.segmentId}
                            onChange={item => {
                                formik.setFieldValue('segmentId', item.target.value)
                                formik.setFieldValue(
                                    'segment',
                                    machineSegmentOption.find(
                                        segment =>
                                            String(segment.value) === String(item.target.value),
                                    )?.label,
                                )
                                formik.setFieldValue('seriesId', 0)
                                formik.setFieldValue('series', '')
                                formik.setFieldValue('model', '')
                                formik.setFieldValue('modelId', 0)
                            }}
                            required
                            options={machineSegmentOption}
                            placeHolderOption={`${t('fields.select')} ${t('fields.segment')}`}
                            showPlaceHolder
                            name="segment"
                            label={t('fields.segment')}
                            error={formik.touched.segment ? formik.errors.segment : undefined}
                        />
                        <Select
                            horizontal
                            value={formik.values.seriesId}
                            required
                            onChange={item => {
                                formik.setFieldValue('seriesId', item.target.value)
                                formik.setFieldValue(
                                    'series',
                                    machineSeriesOption.find(
                                        segment =>
                                            String(segment.value) === String(item.target.value),
                                    )?.label,
                                )
                                formik.setFieldValue('model', '')
                                formik.setFieldValue('modelId', 0)
                            }}
                            options={machineSeriesOption}
                            placeHolderOption={`${t('fields.select')} ${t('fields.series')}`}
                            showPlaceHolder
                            name="series"
                            label={t('fields.series')}
                            error={formik.touched.series ? formik.errors.series : undefined}
                        />
                        <Select
                            horizontal
                            value={formik.values.modelId}
                            required
                            onChange={item => {
                                formik.setFieldValue('modelId', item.target.value)
                                formik.setFieldValue(
                                    'model',
                                    machineModelOption.find(
                                        segment =>
                                            String(segment.value) === String(item.target.value),
                                    )?.label,
                                )
                            }}
                            options={machineModelOption}
                            placeHolderOption={`${t('fields.select')} ${t('fields.model')}`}
                            showPlaceHolder
                            name="model"
                            label={t('fields.model')}
                            error={formik.touched.model ? formik.errors.model : undefined}
                        />
                        {formik.values.segment === 'Verbrandings heftruck' && (
                            <Select
                                horizontal
                                value={formik.values.fuel}
                                onChange={item => formik.setFieldValue('fuel', item.target.value)}
                                options={FuelTypeOptions}
                                placeHolderOption={`${t('fields.select')} ${t('fields.fuel_type')}`}
                                showPlaceHolder
                                name="fuel"
                                label={t('fields.fuel_type')}
                                error={formik.touched.fuel ? formik.errors.fuel : undefined}
                            />
                        )}
                    </div>
                </Panel>
            </Collapse>
        </div>
    )
}

export default MachineDescriptions
