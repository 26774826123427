import { useTranslation } from 'react-i18next'

// eslint-disable-next-line no-shadow
export enum STOCK_STATUS {
    OPEN = 1,
    RESERVED = 2,
    SOLD = 3,
}

const textStyle = 'whitespace-normal text-center rounded-full px-4 text-white w-32'

const GetStockStatusText = (props: { pendingStatus: number }) => {
    const { pendingStatus } = props
    const { t } = useTranslation()

    switch (pendingStatus) {
        case STOCK_STATUS.OPEN:
            return <div className={`bg-zinc-600 ${textStyle}`}>{t('api.open')}</div>
        case STOCK_STATUS.RESERVED:
            return <div className={`bg-successColor ${textStyle}`}>{t('api.reserved')}</div>
        case STOCK_STATUS.SOLD:
            return <div className={`bg-blue-300 ${textStyle}`}>{t('api.sold')}</div>
        default:
            return <div className={`bg-zinc-600 ${textStyle}`}>{t('api.open')}</div>
    }
}

export default GetStockStatusText
