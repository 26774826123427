/* eslint-disable jsx-a11y/anchor-is-valid */
import DeleteModal from 'components/modal/deleteModal'
import ConfigurationSiteSurvey from 'modules/novo-configuration/components/ConfigurationSiteSurvey'
import { useState } from 'react'
import { AiOutlineCalculator, AiOutlineEye } from 'react-icons/ai'
import { MdArrowDropDown, MdMoreTime } from 'react-icons/md'
import { useMutation } from 'react-query'
import Modal from 'react-responsive-modal'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { errorToast, successToast } from 'utils/toasterUtil'
import { BiHistory, BiTrashAlt } from 'react-icons/bi'
import { Button, Dropdown, Menu } from 'antd'
import { useTranslation } from 'react-i18next'
import { HiOutlineTranslate } from 'react-icons/hi'
import { PRICING_STATUS } from 'utils/pricingStatus'
import { ROLE_TYPE, useAuth } from 'modules/auth/context/useAuth'
import { removeConfigurationFromQuotes } from '../queries/quotes'
import { useQuoteLayout } from '../QuoteLayout'

const QuoteAction = ({
    configuration,
    isSameUser,
    refetch,
    priceStatus,
    setCurrentConfiguration,
}: {
    configuration: any
    isSameUser: boolean
    refetch: () => void
    priceStatus: number
    setCurrentConfiguration: (value: string) => void
}) => {
    const { quoteId } = useParams()
    const { userExtra } = useAuth()
    const navigate = useNavigate()
    const { recalculateQuoteMutation, quoteDetail } = useQuoteLayout()
    const [siteSurveyModal, setSiteSurveyModal] = useState<boolean>(false)
    const [removeConfiguration, setRemoveConfiguration] = useState(false)
    const { t } = useTranslation()

    const removeConfigurationMutation = useMutation(
        ['approveQuoteRequest'],
        () =>
            removeConfigurationFromQuotes({
                quoteId,
                configurationId: configuration.id,
                isNovo: configuration?.is_novo || false,
            }),
        {
            onSuccess: async (data: any) => {
                await refetch()
                toast(data?.message || t('message.remove_configuration_success'), successToast)
                setRemoveConfiguration(false)
            },
            onError: (error: any) => {
                toast(error?.message || t('message.remove_configuration_failed'), errorToast)
            },
        },
    )

    const handleRemoveConfigurationRemove = async () => {
        await removeConfigurationMutation.mutate()
    }

    return (
        //     <a
        //     href={`/quotes/${quoteId}/${configuration.id}?is_novo=${configuration.is_novo}`}
        //     title="See Configuration"
        //     className=" cursor-pointer hover:text-primary text-lg"
        // >
        //     <AiOutlineEye size={25} className="text-placeholderColor hover:text-primary" />
        // </a>
        <div className="flex items-center gap-4 py-2">
            <Dropdown
                overlay={
                    <Menu>
                        <Menu.Item
                            key="1"
                            icon={<AiOutlineCalculator size={16} />}
                            onClick={() => {
                                setSiteSurveyModal(true)
                            }}
                        >
                            {t('api.site_survey')}
                        </Menu.Item>
                        <Menu.Item
                            key="2"
                            icon={<MdMoreTime size={16} />}
                            onClick={() => {
                                if (
                                    parseInt(String(priceStatus), 10) >=
                                    PRICING_STATUS.FLEET_ADJUSTED
                                ) {
                                    toast.error('message.reset_status_to_draft_to_add_extra')
                                } else {
                                    setCurrentConfiguration(
                                        configuration?.name || configuration?.model,
                                    )
                                    if (quoteDetail?.need_update) {
                                        recalculateQuoteMutation.mutate(null, {
                                            onSuccess: () => {
                                                navigate({
                                                    pathname: `/quotes/${quoteId}/${configuration.id}/add-extra`,
                                                    search: `?is_electric=${configuration.is_electric}`,
                                                })
                                            },
                                        })
                                    } else {
                                        navigate({
                                            pathname: `/quotes/${quoteId}/${configuration.id}/add-extra`,
                                            search: `?is_electric=${configuration.is_electric}`,
                                        })
                                    }
                                }
                            }}
                        >
                            {t('api.add_extra')}
                        </Menu.Item>
                        <Menu.Item
                            key="3"
                            icon={<AiOutlineCalculator size={16} />}
                            onClick={() => {
                                refetch()
                                setCurrentConfiguration(
                                    `${configuration?.name || configuration?.model} ${
                                        configuration?.description
                                    }`,
                                )
                                navigate(
                                    `/quotes/${quoteId}/${
                                        configuration.id
                                    }/pricing?pricing_status=${priceStatus || '0'}`,
                                )
                            }}
                        >
                            {t('api.price_calculator')}
                        </Menu.Item>
                        <Menu.Item
                            key="4"
                            icon={<AiOutlineEye size={16} />}
                            onClick={() => {
                                setCurrentConfiguration(configuration?.name || configuration?.model)
                                navigate(
                                    `/quotes/${quoteId}/${configuration.id}?is_novo=${configuration.is_novo}`,
                                )
                            }}
                        >
                            {t('api.show')}
                        </Menu.Item>
                        <Menu.Item
                            key="5"
                            icon={<BiHistory size={16} />}
                            onClick={() => {
                                setCurrentConfiguration(configuration?.name || configuration?.model)
                                navigate(
                                    `/quotes/${quoteId}/${configuration.id}/history?is_novo=${
                                        configuration?.is_novo || 'true'
                                    }`,
                                )
                            }}
                        >
                            {t('api.see_history')}
                        </Menu.Item>
                        {userExtra.role_id === ROLE_TYPE.sales_manager && (
                            <Menu.Item
                                key="6"
                                icon={<HiOutlineTranslate size={16} />}
                                onClick={() => {
                                    setCurrentConfiguration(
                                        configuration?.name || configuration?.model,
                                    )
                                    navigate(
                                        `/quotes/${quoteId}/${configuration.id}/translate-parts`,
                                    )
                                }}
                            >
                                {t('api.translate_parts_description')}
                            </Menu.Item>
                        )}
                        <Menu.Item
                            key="7"
                            icon={<BiTrashAlt size={16} />}
                            onClick={() => {
                                setCurrentConfiguration(configuration?.name || configuration?.model)
                                setRemoveConfiguration(true)
                            }}
                        >
                            {t('api.delete_configuration')}
                        </Menu.Item>
                    </Menu>
                }
            >
                <Button>
                    <div className="flex gap-4 justify-between items-center">
                        <p>{t('api.actions')}</p>
                        <MdArrowDropDown size={24} />
                    </div>
                </Button>
            </Dropdown>
            {/* {configuration?.is_novo && (
                <div
                    onClick={() => {
                        navigate({
                            pathname: `/quotes/${quoteId}/${configuration.id}/add-extra`,
                            search: `?is_electric=${configuration.is_electric}`,
                        })
                    }}
                >
                    <div title="Add More" className=" cursor-pointer hover:text-primary text-lg">
                        <MdMoreTime
                            size={25}
                            className="text-placeholderColor hover:text-primary"
                        />
                    </div>
                </div>
            )}
            <div title="Price Calculator" className=" cursor-pointer hover:text-primary text-lg">
                <AiOutlineCalculator
                    size={25}
                    className="text-placeholderColor hover:text-primary"
                />
            </div>
            <div
                onClick={() => setSiteSurveyModal(true)}
                title="Site Survey"
                className=" cursor-pointer hover:text-primary text-lg"
            >
                <RiSurveyLine size={25} className="text-placeholderColor hover:text-primary" />
            </div>
            <a
                href={`/quotes/${quoteId}/${configuration.id}/history?is_novo=${configuration.is_novo}`}
                title="Configuration History"
                className=" cursor-pointer hover:text-primary text-lg"
            >
                <BiHistory size={25} className="text-placeholderColor hover:text-primary" />
            </a>
            {isSameUser ? (
                <div
                    onClick={() => setRemoveConfiguration(true)}
                    title="Delete Quote"
                    className=" cursor-pointer hover:text-primary text-lg"
                >
                    <BiTrashAlt size={25} className="text-placeholderColor hover:text-primary" />
                </div>
            ) : null} */}
            <Modal
                center
                open={siteSurveyModal}
                onClose={() => setSiteSurveyModal(false)}
                showCloseIcon={false}
                classNames={{
                    root: 'rounded-lg',
                    modal: 'rounded-lg w-[80%]',
                }}
            >
                <ConfigurationSiteSurvey
                    isNovo={configuration.is_novo}
                    closeModal={() => setSiteSurveyModal(false)}
                    configurationId={configuration.id}
                />
            </Modal>
            <DeleteModal
                modal={removeConfiguration}
                closeModal={() => setRemoveConfiguration(!removeConfiguration)}
                label={t('api.are_you_sure_you_want_to_delete')}
                title={`${t('api.configuration')}: ${
                    configuration?.name || configuration?.model || ''
                }`}
                onConfirm={handleRemoveConfigurationRemove}
                loading={removeConfigurationMutation.isLoading}
            />
        </div>
    )
}

export default QuoteAction
