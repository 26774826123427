/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Navigate, RouteObject, useLocation, useNavigate } from 'react-router-dom'

import Splash from 'components/splash'
import DashboardLayout from 'layout/DashboardLayout'
import { AuthLayout, ForgetPassword, Login } from 'modules/auth'
import { useAuth } from 'modules/auth/context/useAuth'
import ResetPassword from 'modules/auth/views/resetPassword'
import Configuration from 'modules/configuration'
import ConfigurationSuccess from 'modules/configuration/views/configurationSuccess'
import CreateConfiguration from 'modules/configuration/views/createConfiguration'
import DetailConfiguartion from 'modules/configuration/views/detailConfiguration'
import { Bulletins, CustomerFeedback, Dashboard, Policies, Profile } from 'modules/dashboard'
import HomeDashboardLayout from 'modules/dashboard/HomeDashboardLayout'
import ConfigurationHistory from 'modules/history'
import Order from 'modules/order'
import OrderLayout from 'modules/order/OrderLayout'
import OrderDetail from 'modules/order/views/OrderDetail'
import OrderForm from 'modules/order/views/OrderForm'
import OrderInfo from 'modules/order/views/OrderInfo'
import Quotes from 'modules/quotes'
import QuoteLayout from 'modules/quotes/QuoteLayout'
import CreateQuote from 'modules/quotes/views/CreateQuote'
import MultipleDiscountRequest from 'modules/quotes/views/MultipleDiscountRequest'
import QuoteDetail from 'modules/quotes/views/QuoteDetail'
import QuoteInfo from 'modules/quotes/views/QuoteInfo'

import LeaseCalculation from 'modules/lease-calculation/LeaseCalculation'
import { Disclaimer, Privacy } from 'modules/shared'

import NovoConfiguration from 'modules/novo-configuration'
import NovoHistory from 'modules/novo-configuration/views/NovoHistory'
import NovoImport from 'modules/novo-configuration/views/NovoImport'
import NovoDetail from 'modules/novo-configuration/views/novoDetail'

import BlankLayout from 'layout/BlankLayout'
import RoleSelector from 'modules/auth/views/roleSelector'
import NotFound from 'modules/errors/NotFound'
import StockDetail from 'modules/novo-configuration/views/stockDetail'
import PricingOrderOverview from 'modules/order/views/PricingOrderOverview'
import EditConfigurationParts from 'modules/quotes/components/EditConfigurationParts'
import TranslateConfigurationParts from 'modules/quotes/components/TranslateConfigurationParts'
import AddExtraQuote from 'modules/quotes/views/AddExtraQuote'
import DownloadDocument from 'modules/quotes/views/DownloadDocument'
import FreeText from 'modules/quotes/views/FreeText'
import Overview from 'modules/quotes/views/Overview'
import Pricing from 'modules/quotes/views/Pricing'
import RentalContract from 'modules/quotes/views/RentalContract'
import RequestTs from 'modules/request_ts/views'
import RequestDetail from 'modules/request_ts/views/RequestDetail'
import RequirementMatrix from '../modules/quotes/components/RequirementMatrix'
import UploadDocument from '../modules/quotes/components/UploadDocument'
import SiteSurvey from '../modules/siteSurvey'
import AddSiteSurvey from '../modules/siteSurvey/components/AddSiteSurvey'
import {
    BULLETIN_ROUTE,
    // DISCOUNT_HISTORY_ROUTE,
    // DISCOUNT_REQUEST_ROUTE,
    CUSTOMER_FEEDBACK_ROUTE,
    DISCLAIMER_ROUTE,
    NOVO_CONFIGURATION_ROUTE,
    POLICY_ROUTE,
    PRIVACY_ROUTE,
    PROFILE_ROUTE,
    USED_TRUCK_ROUTE,
} from './appRoutes'

interface RequireAuthProps {
    children: React.ReactElement
    isUserLoggedIn: boolean
    loading: boolean
    isPublic?: boolean
    isAuth?: boolean
    location: any
}

const RequireAuth = ({
    children,
    isUserLoggedIn,
    isPublic,
    isAuth,
    location,
    loading,
}: RequireAuthProps) => {
    const navigate = useNavigate()
    // for route to redirect after checkAuthentication
    const privatePathNavigation: string = location.state ? location.state.from : '/'

    const publicPathNavigation: string = location.state ? location.state.from : '/auth/login'

    if (loading) {
        return <Splash />
    }

    if (isUserLoggedIn && isPublic) {
        return <Navigate to={privatePathNavigation} state={{ from: location }} />
    }

    if (!isUserLoggedIn && isAuth) {
        localStorage.setItem('CPQ_REDIRECT_URL', JSON.stringify(location))
        return <Navigate to={publicPathNavigation} state={{ from: location }} />
    }

    const redirectUrl =
        localStorage.getItem('CPQ_REDIRECT_URL') &&
        JSON.parse(localStorage.getItem('CPQ_REDIRECT_URL') || '{}')
    if (redirectUrl && isAuth) {
        localStorage.removeItem('CPQ_REDIRECT_URL')
        navigate(redirectUrl.pathname + redirectUrl.search)
    }

    return children
}

const useRouteData = () => {
    const { isUserLoggedIn } = useAuth()
    const location = useLocation()

    const routes: RouteObject[] = [
        {
            path: '/',
            element: (
                <RequireAuth
                    isUserLoggedIn={isUserLoggedIn}
                    loading={false}
                    location={location}
                    isPublic={false}
                    isAuth
                >
                    <DashboardLayout />
                </RequireAuth>
            ),
            children: [
                {
                    path: '',
                    element: <HomeDashboardLayout />,
                    children: [
                        {
                            index: true,
                            element: <Dashboard />,
                        },
                        {
                            path: PROFILE_ROUTE,
                            element: <Profile />,
                        },
                        // {
                        //     path: DISCOUNT_REQUEST_ROUTE,
                        //     element: <Discounts />,
                        // },
                        // {
                        //     path: DISCOUNT_HISTORY_ROUTE,
                        //     element: <Discounts />,
                        // },
                        {
                            path: CUSTOMER_FEEDBACK_ROUTE,
                            element: <CustomerFeedback />,
                        },
                        {
                            path: BULLETIN_ROUTE,
                            element: <Bulletins />,
                        },
                        {
                            path: POLICY_ROUTE,
                            element: <Policies />,
                        },
                    ],
                },

                // configuration
                {
                    path: 'configuration',
                    children: [
                        {
                            index: true,
                            element: <Configuration />,
                        },
                        {
                            path: 'create',
                            element: <CreateConfiguration />,
                        },
                        {
                            path: 'create/:configurationId',
                            element: <CreateConfiguration />,
                        },
                        {
                            path: 'create/:configurationId/:from',
                            element: <CreateConfiguration />,
                        },
                        {
                            path: 'edit/:configurationId/:from',
                            element: <CreateConfiguration />,
                        },
                        {
                            path: 'edit/:configurationId',
                            element: <CreateConfiguration />,
                        },
                        {
                            path: 'success',
                            element: <ConfigurationSuccess />,
                        },
                        {
                            path: ':from/:configurationId',
                            element: <DetailConfiguartion />,
                        },
                        {
                            path: ':configurationId',
                            element: <DetailConfiguartion />,
                        },
                    ],
                },

                // orders
                {
                    path: '/order',
                    children: [
                        {
                            index: true,
                            element: <Order />,
                        },
                        {
                            path: ':orderId',
                            element: <OrderLayout />,
                            children: [
                                {
                                    index: true,
                                    element: <OrderDetail />,
                                },
                                {
                                    path: 'order-form',
                                    element: <OrderForm />,
                                },
                                {
                                    path: ':quoteId',
                                    children: [
                                        {
                                            index: true,
                                            element: <OrderDetail />,
                                        },
                                        {
                                            path: 'download-document',
                                            element: <DownloadDocument />,
                                        },
                                        {
                                            path: 'upload-document',
                                            element: <UploadDocument />,
                                        },
                                        {
                                            path: 'rental-contract',
                                            element: <RentalContract />,
                                        },
                                        {
                                            path: 'free-text',
                                            element: <FreeText />,
                                        },
                                        {
                                            path: 'requirement-matrix',
                                            element: <RequirementMatrix />,
                                        },
                                        {
                                            path: ':configurationId',
                                            children: [
                                                {
                                                    index: true,
                                                    element: <OrderInfo />,
                                                },
                                                {
                                                    path: 'pricing-overview',
                                                    element: <PricingOrderOverview />,
                                                },
                                                {
                                                    path: 'history',
                                                    element: <ConfigurationHistory />,
                                                },
                                                {
                                                    path: 'translate-parts',
                                                    element: <TranslateConfigurationParts />,
                                                },
                                                {
                                                    path: 'translate-parts/:partNumber/edit',
                                                    element: <EditConfigurationParts />,
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },

                // quotes
                {
                    path: '/quotes',
                    children: [
                        {
                            index: true,
                            element: <Quotes />,
                        },
                        {
                            path: 'create',
                            element: <CreateQuote />,
                        },
                        {
                            path: ':quoteId',
                            element: <QuoteLayout />,
                            children: [
                                {
                                    index: true,
                                    element: <QuoteDetail />,
                                },
                                {
                                    path: 'edit',
                                    element: <CreateQuote />,
                                },
                                {
                                    path: 'download-document',
                                    element: <DownloadDocument />,
                                },
                                {
                                    path: 'multiple-discount-request',
                                    element: <MultipleDiscountRequest />,
                                },
                                {
                                    path: 'overview',
                                    element: <Overview />,
                                },
                                {
                                    path: ':configurationId/add-extra',
                                    element: <AddExtraQuote />,
                                },
                                {
                                    path: 'upload-document',
                                    element: <UploadDocument />,
                                },
                                {
                                    path: 'rental-contract',
                                    element: <RentalContract />,
                                },
                                {
                                    path: 'free-text',
                                    element: <FreeText />,
                                },
                                {
                                    path: 'requirement-matrix',
                                    element: <RequirementMatrix />,
                                },
                                {
                                    path: ':configurationId',
                                    children: [
                                        {
                                            index: true,
                                            element: <QuoteInfo />,
                                        },
                                        {
                                            path: 'history',
                                            element: <ConfigurationHistory />,
                                        },
                                        {
                                            path: 'translate-parts',
                                            element: <TranslateConfigurationParts />,
                                        },
                                        {
                                            path: 'translate-parts/:partNumber/edit',
                                            element: <EditConfigurationParts />,
                                        },
                                        {
                                            path: 'pricing',
                                            element: <Pricing />,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },

                // request-ts
                {
                    path: '/request-ts',
                    children: [
                        {
                            index: true,
                            element: <RequestTs />,
                        },
                        {
                            path: ':requestId/detail',
                            element: <RequestDetail />,
                        },
                        {
                            path: ':requestId/:quoteId',
                            children: [
                                {
                                    element: <QuoteLayout readonly />,
                                    children: [
                                        {
                                            index: true,
                                            element: <QuoteDetail readonly />,
                                        },
                                    ],
                                },
                                {
                                    path: ':configurationId',
                                    children: [
                                        {
                                            index: true,
                                            element: <QuoteInfo />,
                                        },
                                    ],
                                },
                                {
                                    path: '*',
                                    element: (
                                        <BlankLayout>
                                            <NotFound />
                                        </BlankLayout>
                                    ),
                                },
                            ],
                        },
                        {
                            path: '*',
                            element: (
                                <BlankLayout>
                                    <NotFound />
                                </BlankLayout>
                            ),
                        },
                    ],
                },

                // site-survey
                {
                    path: 'site-survey',
                    children: [
                        {
                            index: true,
                            element: <SiteSurvey />,
                        },
                        {
                            path: 'create',
                            element: <AddSiteSurvey />,
                        },
                        {
                            path: 'copy/:copySiteSurveyId',
                            element: <AddSiteSurvey />,
                        },
                        {
                            path: ':siteSurveyId',
                            element: <AddSiteSurvey />,
                        },
                    ],
                },

                // lease calculation
                {
                    path: '/lease-calculation',
                    children: [
                        {
                            path: ':configurationId',
                            element: <LeaseCalculation />,
                        },
                    ],
                },

                // novo configuration
                {
                    path: NOVO_CONFIGURATION_ROUTE,
                    children: [
                        {
                            index: true,
                            element: <NovoConfiguration />,
                        },
                        {
                            path: 'import',
                            element: <NovoImport />,
                        },
                        {
                            path: 'import-stock',
                            element: <NovoImport />,
                        },
                        {
                            path: ':novoId',
                            element: <NovoDetail />,
                        },
                        {
                            path: 'stock/:stockId',
                            element: <StockDetail />,
                        },
                        {
                            path: 'history/:novoId',
                            element: <NovoHistory />,
                        },
                    ],
                },
                {
                    path: USED_TRUCK_ROUTE,
                    children: [
                        {
                            index: true,
                            element: <NovoConfiguration />,
                        },
                        {
                            path: 'import',
                            element: <NovoImport />,
                        },
                        {
                            path: 'import-stock',
                            element: <NovoImport />,
                        },
                        {
                            path: ':novoId',
                            element: <NovoDetail goBackRoute={USED_TRUCK_ROUTE} />,
                        },
                        {
                            path: 'stock/:stockId',
                            element: <StockDetail goBackRoute={USED_TRUCK_ROUTE} />,
                        },
                        {
                            path: 'history/:novoId',
                            element: <NovoHistory goBackRoute={USED_TRUCK_ROUTE} />,
                        },
                    ],
                },
            ],
        },
        {
            path: 'auth',
            element: (
                <RequireAuth
                    isUserLoggedIn={isUserLoggedIn}
                    loading={false}
                    location={location}
                    isPublic
                    isAuth={false}
                >
                    <AuthLayout />
                </RequireAuth>
            ),
            children: [
                {
                    path: 'login',
                    element: <Login />,
                },
                {
                    path: 'forget-password',
                    element: <ForgetPassword />,
                },
                {
                    path: 'password-reset/:resetToken',
                    element: <ResetPassword />,
                },
                {
                    path: 'role-selector',
                    element: <RoleSelector />,
                },
            ],
        },
        {
            path: 'password-reset/:resetToken',
            element: (
                <RequireAuth
                    isUserLoggedIn={isUserLoggedIn}
                    loading={false}
                    location={location}
                    isPublic
                    isAuth={false}
                >
                    <AuthLayout />
                </RequireAuth>
            ),
            children: [
                {
                    index: true,
                    element: <ResetPassword />,
                },
            ],
        },
        {
            path: PRIVACY_ROUTE,
            element: <Privacy />,
        },
        {
            path: DISCLAIMER_ROUTE,
            element: <Disclaimer />,
        },
        {
            path: '*',
            element: (
                <BlankLayout>
                    <NotFound />
                </BlankLayout>
            ),
        },
    ]

    return {
        routes,
    }
}

export default useRouteData
