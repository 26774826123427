/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios'

interface CallAxiosAPI {
    url: string
    method: Method
    data?: any
    headers?: any
    params?: string
    isAuthentication?: boolean
    isHeffiq?: boolean
    responseType?: any
}

const baseUrl = `${process.env.REACT_APP_API_URL}`

const heffiqUrl = `${process.env.REACT_APP_API_URL}v2`

export const callAxios = async ({
    url,
    method,
    data,
    headers,
    params,
    isHeffiq,
    responseType,
}: CallAxiosAPI) => {
    const token = await localStorage.getItem('CPQ_ACCESS_TOKEN')
    const accessToken = token !== null ? JSON.parse(token) : null
    const language = localStorage.getItem('i18nextLng') || 'nl'
    const config: AxiosRequestConfig = {
        method: method || 'GET',
        url: isHeffiq ? `${heffiqUrl}/${url}` : `${baseUrl}${url}`,
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Accept-Language': language.toLowerCase(),
            'ngrok-skip-browser-warning': true,
            Authorization: accessToken !== null ? `Bearer ${accessToken}` : '',
            ...headers,
        },
        data,
        params,
        responseType,
        timeout: 0, // 20 seconds
    }
    return axios(config)
        .then((res: AxiosResponse<any, any>) => {
            return res
        })
        .catch(async err => {
            if (
                err?.response?.status === 401 &&
                err?.response?.data?.message === 'Unauthenticated.'
            ) {
                localStorage.removeItem('CPQ_LOGGED_IN_USER')
                localStorage.removeItem('CPQ_ACCESS_TOKEN')
                localStorage.clear()
                window.location.href = '/auth/login'
            }
            if (err.response.data instanceof Blob) {
                const blobText = await err.response.data.text()
                throw new Error(
                    String(JSON.parse(blobText).message) ||
                        'Something went wrong. Please try again later.',
                )
            } else {
                throw new Error(
                    err.response.data.message || 'Something went wrong. Please try again later.',
                )
            }
        })
}
