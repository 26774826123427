import { HOME_ROUTE, NOVO_CONFIGURATION_ROUTE, REQUEST_TS } from 'route/appRoutes'

export const data = [
    {
        label: 'api.home',
        role: [],
        link: HOME_ROUTE,
    },
    {
        label: 'api.site_survey',
        role: [],
        link: 'site-survey',
    },
    {
        label: 'api.novo_configuration',
        role: [],
        link: NOVO_CONFIGURATION_ROUTE,
    },
    {
        label: 'api.used_truck',
        role: [],
        link: 'used-truck',
    },
    // hide for heffiq as per requirement. It was decided that configuration is not required for heffiq
    // {
    //     label: 'api.configurations',
    //     role: [],
    //     link: '/configuration',
    // },
    {
        label: 'api.quotes',
        role: [],
        link: '/quotes',
    },
    {
        label: 'api.orders',
        role: [],
        link: '/order',
    },
    // {
    //     label: 'api.discount_requests',
    //     role: [],
    //     link: DISCOUNT_REQUEST_ROUTE,
    // },
    {
        label: 'api.request_ts',
        // role: ['Servicer', 'Technical Support', 'Sales Support'],
        role: [],
        link: REQUEST_TS,
    },
]
