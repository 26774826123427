/* eslint-disable @typescript-eslint/ban-types */
import classNames from 'classnames'
import { IoIosArrowBack } from 'react-icons/io'
import { Outlet, useNavigate, useOutletContext, useParams, useSearchParams } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { useRef, useState } from 'react'
import { GiHamburgerMenu } from 'react-icons/gi'
import { RiCloseFill } from 'react-icons/ri'

import { getPillsColor } from 'utils/pills'
import { ROLE_TYPE, useAuth } from 'modules/auth/context/useAuth'
import { useTranslation } from 'react-i18next'
import useOutsideClick from 'hooks/useOutsideClick'
import { toast } from 'react-toastify'
import GetPricingStatusText from 'utils/pricingStatus'
import { getAllSelectedTemplate, getQuoteDetail, recalculateQuote } from './queries/quotes'
import QuoteDetailActions from './components/QuoteDetailActions'
import Loading from '../../components/loading'

const cardBox = 'bg-white drop-shadow-md rounded-md flex flex-col gap-4'
type ContextType = {
    quoteConfigurations: [] | null
    quoteDetail: any
    recalculateQuoteMutation: any
    isLoading: boolean
    isRefetching: boolean
    setCurrentConfiguration: (value: string) => void
    refetch: () => void
}

const QuoteLayout = ({ readonly }: { readonly?: boolean }) => {
    const sideMenuRef = useRef<any>()
    const [quoteDetail, setQuoteDetail] = useState<any>({})
    const [currentConfiguration, setCurrentConfiguration] = useState('')
    const [quoteConfigurations, setquoteConfigurations] = useState([])
    const [, setColor] = useState<any>({})
    const [openMenu, setOpenMenu] = useState<boolean>(false)
    const [searchParams] = useSearchParams()
    const pricingStatus = searchParams.get('pricing_status')
    const [showUnlockModal, setShowUnlockModal] = useState<boolean>(false)

    const { quoteId, configurationId } = useParams()
    const navigate = useNavigate()
    const { user, userExtra } = useAuth()
    const { t } = useTranslation()

    const recalculateQuoteMutation = useMutation(
        ['recalculateQuote'],
        () => recalculateQuote(Number(quoteId)),
        {
            onError: (err: Error) => {
                toast.error(err?.message || t('message.recalculate_quote_failed'))
            },
        },
    )

    const { isLoading, refetch, isRefetching } = useQuery(
        ['quoteDetail', quoteId],
        () =>
            getQuoteDetail(
                quoteId || '',
                user.id,
                userExtra.role_id === ROLE_TYPE.technical_support,
            ),
        {
            refetchOnMount: true,
            onSuccess: data => {
                setquoteConfigurations(data?.data?.data?.configurations || [])
                setQuoteDetail(data?.data?.data?.quote || {})
                if (data?.data?.data?.quote.need_update) {
                    recalculateQuoteMutation.mutate()
                }
                setColor(getPillsColor(data?.data?.data?.quote.status))
            },
        },
    )

    const templateList = useQuery(['templateList'], () => getAllSelectedTemplate(userExtra.role_id))

    useOutsideClick(sideMenuRef, () => {
        if (openMenu) {
            setOpenMenu(false)
        }
    })

    if (isLoading) {
        return (
            <div className="h-[84vh] flex w-full justify-center items-center">
                <Loading />
            </div>
        )
    }

    const handleBack = () => {
        if (
            window.location.href.indexOf('pricing') !== -1 ||
            window.location.href.indexOf('add-extra') !== -1 ||
            window.location.href.indexOf('is_novo') !== -1 ||
            window.location.href.indexOf('history') !== -1 ||
            window.location.href.indexOf('overview') !== -1 ||
            window.location.href.indexOf('translate-parts') !== -1 ||
            window.location.href.indexOf('free-text') !== -1 ||
            window.location.href.indexOf('download-document') !== -1
        ) {
            navigate(`/quotes/${quoteId}`)
        } else {
            navigate('/quotes')
        }
    }

    return (
        <div className="flex flex-col gap-4 relative">
            <div className="flex items-center sticky top-0 z-50 bg-white rounded-md p-4">
                {!readonly && (
                    <div
                        ref={sideMenuRef}
                        className={classNames(
                            cardBox,
                            openMenu
                                ? 'block absolute z-[80] top-4 md:top-12 right-5 w-[90%] md:w-1/4 drop-shadow-2xl border border-base-400 h-[60vh] z-[9] py-4'
                                : 'w-1/4 max-w-[22rem] md:relative top-0 left-0 md:drop-shadow-none md:border-none hidden z-[9]',
                        )}
                    >
                        <QuoteDetailActions
                            quoteDetail={quoteDetail}
                            refetch={refetch}
                            configurationList={quoteConfigurations}
                            closeModal={() => setOpenMenu(false)}
                            templates={templateList?.data?.data?.selected_templates || []}
                        />
                    </div>
                )}
                <div className="flex w-full items-center gap-3">
                    <div
                        className="flex items-center hover:text-primary cursor-pointer"
                        onClick={handleBack}
                    >
                        <span>
                            <IoIosArrowBack size={24} color="#E30613" />
                        </span>
                        <span className="font-bold">{t('api.go_back')}</span>
                    </div>
                    <div className="flex flex-col md:flex-row items-start md:items-center justify-start gap-2 px-4">
                        <div>
                            <p className="text-lg text-primary">{quoteDetail.quotation_name},</p>
                        </div>
                        <div className="flex justify-start text-lg gap-4 items-center">
                            <p className="text-md text-placeholderColor">
                                {quoteDetail.quotation_for}
                            </p>
                        </div>
                        {currentConfiguration ? (
                            <>
                                <div className="flex justify-start text-lg gap-4 items-center">
                                    <p className="text-extrabold text-md">
                                        {t('api.machine_model')}:
                                    </p>
                                </div>
                                <div className="flex justify-start text-lg gap-4 items-center">
                                    <p className="text-extrabold text-md text-placeholderColor truncate w-[520px]">
                                        {currentConfiguration}
                                    </p>
                                </div>
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
                <div className="flex gap-4 items-center">
                    {pricingStatus && (
                        <GetPricingStatusText pendingStatus={parseInt(String(pricingStatus), 10)} />
                    )}
                    {!readonly && (
                        <div
                            className=" w-fit cursor-pointer"
                            title="open quote sidebar"
                            onClick={() => setOpenMenu(!openMenu)}
                        >
                            {openMenu ? <RiCloseFill size={30} /> : <GiHamburgerMenu size={30} />}
                        </div>
                    )}
                </div>
            </div>
            <div className="bg-white p-4 rounded-lg flex-1 overflow-scroll">
                <Outlet
                    context={{
                        quoteConfigurations,
                        quoteDetail,
                        recalculateQuoteMutation,
                        isLoading,
                        refetch,
                        isRefetching,
                        setCurrentConfiguration,
                    }}
                />
            </div>
        </div>
    )
}

export default QuoteLayout

export const useQuoteLayout = () => {
    return useOutletContext<ContextType>()
}
