/* eslint-disable array-callback-return */
import Button from 'components/button'
import Loading from 'components/loading'
import { find, map } from 'lodash'
import { useAuth } from 'modules/auth/context/useAuth'
import { getAllSelectedTemplate } from 'modules/quotes/queries/quotes'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueries } from 'react-query'

interface QuoteReviewProps {
    changeStep: (step: number) => void
    formik: any
    isSubmitting: boolean
    configurationList: any
    stockList: any
}

const QuoteReview = (props: QuoteReviewProps) => {
    const { formik, changeStep, isSubmitting, configurationList, stockList } = props
    const { userExtra } = useAuth()
    const { t } = useTranslation()
    const [templateItem, setTemplate] = useState<any>({})

    const quoteInfos = useQueries([
        {
            queryKey: ['templateLisForReview'],
            queryFn: () => getAllSelectedTemplate(userExtra.role_id),
            onSuccess: (data: any) => {
                const templateList = data?.data.selected_templates.map(
                    (template: { template_name: string; template_id: string }) => {
                        return {
                            label: template.template_name,
                            value: template.template_id,
                        }
                    },
                )
                const template = find(templateList, { value: formik.values.template })
                setTemplate(template)
            },
        },
    ])

    const isLoading = quoteInfos.some((result: { isLoading: any }) => result.isLoading)

    if (isLoading) {
        return (
            <div className="flex justify-center items-center bg-white px-8 py-4 rounded-xl gap-4">
                <Loading />
            </div>
        )
    }

    return (
        <div className="bg-white h-full py-8 px-8 rounded-lg flex justify-between flex-col gap-4">
            <div>
                <p className="text-3xl text-center">{t('api.confirm_quote')}</p>
                <div className="flex flex-col align-center items-center">
                    <div className="w-2/4 mt-8 ">
                        <table className="table-auto w-[100%]">
                            <tbody>
                                <tr>
                                    <th className="py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                        {t('api.quote_name')}
                                    </th>
                                    <td className="py-2 font-bold text-lg text-gray-900 whitespace-nowrap text-right">
                                        {formik.values.quotation_name}
                                    </td>
                                </tr>
                                <tr>
                                    <th className="py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                        {t('api.quote_for')}
                                    </th>
                                    <td className="py-2 font-bold text-lg text-gray-900 whitespace-nowrap text-right">
                                        {formik.values.quotation_for}
                                    </td>
                                </tr>
                                {formik.values.configurations.length > 0 && (
                                    <>
                                        <tr>
                                            <th className="py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                                {t('api.total_configuration_list')}
                                            </th>
                                            <td className="py-2 font-bold text-lg text-gray-900 whitespace-nowrap text-right">
                                                {formik.values.configurations.length}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                                {t('api.selected_configuration')}
                                            </th>
                                            <td className="py-2 font-bold truncate max-w-3xl text-lg text-gray-900 whitespace-nowrap text-right flex flex-col">
                                                {map(
                                                    configurationList.filter((configuration: any) =>
                                                        formik.values.configurations.includes(
                                                            configuration.value,
                                                        ),
                                                    ),
                                                    item => (
                                                        <span>{item?.label}</span>
                                                    ),
                                                )}
                                            </td>
                                        </tr>
                                    </>
                                )}
                                {formik.values.stocks.length > 0 && (
                                    <>
                                        <tr>
                                            <th className="py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                                {t('api.total_stock_list')}
                                            </th>
                                            <td className="py-2 font-bold text-lg text-gray-900 whitespace-nowrap text-right">
                                                {formik.values.stocks.length}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="py-2 font-medium text-left text-gray-900 whitespace-nowrap">
                                                {t('api.selected_stock')}
                                            </th>
                                            <td className="py-2 font-bold truncate max-w-3xl text-lg text-gray-900 whitespace-nowrap text-right flex flex-col">
                                                {map(
                                                    stockList.filter((stock: any) =>
                                                        formik.values.stocks.includes(stock.value),
                                                    ),
                                                    item => (
                                                        <span>{item?.label}</span>
                                                    ),
                                                )}
                                            </td>
                                        </tr>
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="flex align-center items-center justify-end align-center gap-8 mt-8">
                <div className="w-48">
                    <Button
                        type="button"
                        label={t('api.back')}
                        variant="primary"
                        isLoading={false}
                        disabled={isSubmitting}
                        onClick={() => changeStep(1)}
                    />
                </div>
                <div className="w-48">
                    <Button
                        type="button"
                        label={t('api.confirm')}
                        isLoading={isSubmitting}
                        disabled={isSubmitting}
                        onClick={() => formik.handleSubmit()}
                    />
                </div>
            </div>
        </div>
    )
}

export default QuoteReview
