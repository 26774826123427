import Stepper, { StepType } from 'components/stepper'
import { useFormik } from 'formik'
import { useAuth } from 'modules/auth/context/useAuth'
import { createQuote, editQuote, ICreateQuote } from 'modules/quotes/queries/quotes'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { omit } from 'lodash'
import ConfigurationSelection from '../views/steps/ConfigurationSelection'
import QuoteInformation from '../views/steps/QuoteInformation'
import QuoteReview from '../views/steps/QuoteReview'

const QuoteForm = (props: { quoteData: any; configurations: any }) => {
    const { quoteData, configurations } = props
    const [activeStep, setActiveStep] = useState<number>(0)
    const { user } = useAuth()
    const { quoteId } = useParams()
    const [searchParams] = useSearchParams()
    const { t } = useTranslation()

    const [configurationList, setConfigurationList] = useState<any>([])
    const [stockList, setStockList] = useState<any>([])
    const navigate = useNavigate()

    function getCaseInsensitiveValue(params: any, key: string) {
        for (const [paramKey, value] of params.entries()) {
            if (paramKey.toLowerCase() === key.toLowerCase()) {
                return value
            }
        }
        return ''
    }

    const companyName = getCaseInsensitiveValue(searchParams, 'company-name')
    const firstName = getCaseInsensitiveValue(searchParams, 'first-name')
    const familyName = getCaseInsensitiveValue(searchParams, 'family-name')
    const gender = getCaseInsensitiveValue(searchParams, 'gender') === 'male' ? 'male' : 'female'
    const street = getCaseInsensitiveValue(searchParams, 'street')
    const houseNumber = getCaseInsensitiveValue(searchParams, 'house-number')
    const postalCode = getCaseInsensitiveValue(searchParams, 'postal-code')
    const city = getCaseInsensitiveValue(searchParams, 'city')
    const department = getCaseInsensitiveValue(searchParams, 'department')

    const create = useMutation(
        'createQuote',
        (formData: ICreateQuote) =>
            quoteId ? editQuote(quoteId, formData) : createQuote(formData, user.id),
        {
            onSuccess: (data: any) => {
                toast(
                    data?.message ||
                        `${t('api.quote')} ${quoteData ? t('api.updated') : t('api.created')} ${t(
                            'message.success',
                        )}`,
                    {
                        type: 'success',
                    },
                )
                navigate('/quotes')
            },
            onError: (error: any) => {
                toast(error?.message || t('message.update_information_failed'), { type: 'error' })
            },
        },
    )

    const validationSchemas = [
        Yup.object().shape({
            quotation_name: Yup.string().required(t('message.name_required')),
            quotation_for: Yup.string().required(t('message.quotation_for_required')),
        }),
        Yup.object().shape({
            configurations: Yup.array().required(t('message.configuration_required')),
        }),
    ]

    const formik = useFormik({
        initialValues: {
            quotation_name: quoteData?.quotation_name || '',
            quotation_for: quoteData?.quotation_for || companyName || '',
            configurations: configurations || [],
            stocks: [],
            connection_type: 'configurations',
            user: quoteData?.user || '',
            status: quoteData?.status || 'pending',
            companyName,
            firstName,
            familyName,
            maleOrFemale: gender,
            address1: street,
            address2: houseNumber,
            postalCode,
            city,
            department,
        },
        validationSchema: validationSchemas[activeStep],
        onSubmit: (values: ICreateQuote) => {
            if (activeStep === 2) {
                const payload: ICreateQuote = companyName
                    ? values
                    : omit(values, [
                          'companyName',
                          'firstName',
                          'familyName',
                          'maleOrFemale',
                          'address1',
                          'address2',
                          'postalCode',
                          'city',
                          'department',
                      ])
                create.mutate(payload)
            } else {
                setActiveStep(activeStep + (quoteId ? 2 : 1))
            }
        },
    })

    const steppers: StepType[] = [
        {
            id: 0,
            step: 0,
            label: t('api.quote_information'),
            component: <QuoteInformation formik={formik} />,
        },
        {
            id: 1,
            step: 1,
            label: t('api.selection'),
            disabled: !!quoteId,
            component: (
                <ConfigurationSelection
                    formik={formik}
                    changeStep={step => setActiveStep(step)}
                    configurationList={configurationList}
                    setConfigurationList={setConfigurationList}
                    stockList={stockList}
                    setStockList={setStockList}
                />
            ),
        },
        {
            id: 2,
            step: 2,
            label: t('api.review_and_configuration'),
            component: (
                <QuoteReview
                    formik={formik}
                    changeStep={(step: number) => setActiveStep(quoteId ? 0 : step)}
                    configurationList={configurationList}
                    stockList={stockList}
                    isSubmitting={create.isLoading}
                />
            ),
        },
    ]

    return (
        <div className="flex flex-col gap-3 h-full">
            <Stepper
                steppers={steppers}
                activeStep={steppers[activeStep]}
                changeActiveStep={(step: number) => setActiveStep(step)}
            />
            <div className="flex-1">{steppers[activeStep].component}</div>
        </div>
    )
}

export default QuoteForm
