import { Descriptions } from 'antd'
import DescriptionsItem from 'antd/es/descriptions/Item'
import TextArea from 'components/textArea'
import { FormikProps } from 'formik'
import { Options } from 'components/select'
import { ISiteSurveyForm } from './AddSiteSurvey'
import useTranslationData from '../../translation/hooks/useTranslationData'
import {
    dockShelterOrRampsOptions,
    floorConditionOption,
    floorOptions,
    workInShiftOptions,
} from './SiteSurveyOptions'

interface ISiteSurveyOverview {
    drive: string
    formik: FormikProps<ISiteSurveyForm>
    theoriticalMaxWorkHour: number
    calculatedCategory: string
    batteryCapacityOption: Options[]
}

const SiteSurveyOverview = (props: ISiteSurveyOverview) => {
    const { drive, formik, theoriticalMaxWorkHour, calculatedCategory, batteryCapacityOption } =
        props
    const {
        translation: { t },
    } = useTranslationData()

    return (
        <Descriptions layout="horizontal" size="small" column={1} bordered>
            <DescriptionsItem label={t('fields.model')}>{formik.values.model}</DescriptionsItem>
            <DescriptionsItem label={`${t('fields.location')} - ${t('fields.department')}`}>
                {formik.values.locationAndDepartment}
            </DescriptionsItem>
            <DescriptionsItem label={t('fields.running_hours')}>
                {formik.values.runningHours} {t('api.hrs')}
            </DescriptionsItem>
            {drive === 'Elektrisch' && (
                <>
                    <DescriptionsItem label={t('fields.max_effective_working_hour')}>
                        {formik.values.maxWorkHour} {t('api.hrs')}
                    </DescriptionsItem>
                    <DescriptionsItem label={t('fields.theoretical_operating_time_per_battery')}>
                        {batteryCapacityOption.length > 0
                            ? `${theoriticalMaxWorkHour.toFixed(2)} ${t('api.hrs')}`
                            : t('fields.theoretical_operating_time_per_battery_calculation_error')}
                    </DescriptionsItem>
                </>
            )}
            <DescriptionsItem label={t('fields.floor')}>
                {t(floorOptions.find(floor => floor.value === formik.values.floor)?.label || '')}
            </DescriptionsItem>
            <DescriptionsItem label={t('fields.condition_of_the_floor')}>
                {t(
                    floorConditionOption.find(
                        floorCondition => floorCondition.value === formik.values.floorCondition,
                    )?.label || '',
                )}
            </DescriptionsItem>
            <DescriptionsItem label={t('fields.ramp')}>
                {t(
                    dockShelterOrRampsOptions.find(ramp => ramp.value === formik.values.ramps)
                        ?.label || '',
                )}
            </DescriptionsItem>
            <DescriptionsItem label={`${t('fields.laadpeeron')} / ${t('fields.dockshelters')}`}>
                {t(
                    dockShelterOrRampsOptions.find(
                        dockShelter => dockShelter.value === formik.values.dockShelters,
                    )?.label || '',
                )}
            </DescriptionsItem>
            <DescriptionsItem label={t('fields.work_in_shifts')}>
                {t(
                    workInShiftOptions.find(
                        workInShift => workInShift.value === formik.values.workInShifts,
                    )?.label || '',
                )}
            </DescriptionsItem>
            <DescriptionsItem label={t('fields.load_center_mm')}>
                {formik.values.loadCenter}
            </DescriptionsItem>
            <DescriptionsItem label={t('fields.outlet_height_for_pallet_mm')}>
                {t(formik.values.outletHeightForPallet)}
            </DescriptionsItem>
            <DescriptionsItem label={t('fields.fork_clearance_mm')}>
                {t(formik.values.forkClearance)}
            </DescriptionsItem>
            <DescriptionsItem label={t('fields.calculated_category')}>
                {t(calculatedCategory)}
            </DescriptionsItem>
            <DescriptionsItem label={t('fields.comment')}>
                <TextArea
                    value={formik.values.comment}
                    name="comment"
                    onChange={formik.handleChange}
                    placeholder={`${t('fields.enter')} ${t('fields.comment')}`}
                />
            </DescriptionsItem>
        </Descriptions>
    )
}

export default SiteSurveyOverview
