import ButtonLoader from 'components/buttonLoader'
import Select, { Options } from 'components/select'
import { useAuth } from 'modules/auth/context/useAuth'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdCancel, MdCheckCircle, MdDone, MdEdit } from 'react-icons/md'
import { CALCULATION_STATUS } from 'utils/calculationStatus'

interface IEditableSelectCell {
    originalValue: number | string
    onChange: (value: number | string) => void
    options: Options[]
    disabled?: boolean
    success?: boolean
    loading?: boolean
    error?: boolean
    index?: number
    unit?: string
    status?: CALCULATION_STATUS
    name: string
    selectedIndex?: number
    approve?: () => void
}

const EditableSelectCell: React.FC<IEditableSelectCell> = props => {
    const {
        originalValue,
        onChange,
        disabled,
        loading,
        options,
        name,
        success,
        status,
        selectedIndex,
        index,
        error,
        unit,
        approve,
    } = props
    const { t } = useTranslation()
    const { authorizationMatrix } = useAuth()
    const [tempValue, setTempValue] = useState<number | string>(originalValue)
    const [edit, setEdit] = useState<boolean>(false)
    const [isBeyondAccess, setIsBeyondAccess] = useState<boolean>(false)

    useEffect(() => {
        if (originalValue) {
            setTempValue(originalValue)
        }
    }, [originalValue, error, success])

    const checkIsBeyondAccess = (value: number) => {
        switch (name) {
            case 'min_investment_group':
                break
            default:
                break
        }
    }

    const handleChange = (value: number) => {
        checkIsBeyondAccess(value)
        setTempValue(value)
    }

    if (loading && selectedIndex === index) {
        return (
            <div className="flex w-full bg-gray-200 justify-end items-center gap-2">
                <ButtonLoader />
            </div>
        )
    }

    return (
        <div className="flex w-full justify-end items-center gap-2">
            <div className="flex items-center">
                <Select
                    options={options}
                    name={name}
                    selectWidth="w-[14rem]"
                    value={tempValue}
                    disabled={disabled || !edit}
                    customStyle={
                        status === CALCULATION_STATUS.ADJUSTED
                            ? 'bg-primary'
                            : status === CALCULATION_STATUS.APPROVED
                            ? 'bg-successColor'
                            : ''
                    }
                    OptionStyle="bg-white"
                    rightContent={
                        <div className="flex gap-2 items-center">
                            {unit && unit}
                            {edit ? (
                                <div className="flex gap-2">
                                    <MdDone
                                        className="cursor-pointer hover:text-green-300"
                                        size={16}
                                        title={t('api.ok')}
                                        onClick={() => {
                                            onChange(tempValue)
                                            setEdit(false)
                                        }}
                                    />
                                    <MdCancel
                                        className="cursor-pointer hover:text-dangerColor"
                                        size={16}
                                        title={t('api.cancel')}
                                        onClick={() => {
                                            setEdit(false)
                                            setTempValue(originalValue)
                                        }}
                                    />
                                </div>
                            ) : undefined}
                        </div>
                    }
                    onChange={e => handleChange(parseFloat(e.target.value))}
                />
                {!edit && !disabled && (
                    <div className="flex gap-2 items-center ml-2">
                        <MdEdit
                            className="mb-1 cursor-pointer"
                            size="16"
                            title={t('api.edit')}
                            onClick={() => setEdit(true)}
                        />
                        {status !== CALCULATION_STATUS.APPROVED && (
                            <MdCheckCircle
                                className="mb-1 cursor-pointer text-green-600 hover:brightness-125"
                                size="16"
                                title={t('api.approve')}
                                onClick={approve}
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default EditableSelectCell
